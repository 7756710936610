import { Link } from "react-router-dom";

const SectionHeader = props => {
    return <div className="flex w-full justify-between items-center mt-12 mb-8">
        <h1 className="text-2xl uppercase font-bold">{props.name}</h1>
        
        {
            props.buttonLink ?
            <Link to={props.buttonLink} className="btn btn-green text-white">{props.buttonLabel}</Link>
            :
            null
        }
        
        
    </div>
}

export default SectionHeader

