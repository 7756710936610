import { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom"

import axios from "axios";

import CaseIcon from '../components/Icons/CaseIcon';
import SectionHeader from "../components/Layout/SectionHeader";
import GetCases from "../components/Cases/GetCases";
import SectionHeaderSmall from "../components/Layout/SectionHeaderSmall";
import ItemSmallInCase from "../components/Item/inSmallInCase";


const CaseRunner = props => {

    // const [activeCases, setActiveCases] = useState({});
    const [itemCategories, setItemCategories] = useState([]);
    const [caseData, setCaseData] = useState([]);
    const [caseItems, setCaseItems] = useState([]);

    useEffect(() => {



        axios.get(`${props.casesURL}/runner/${props.sessionUser.id}`).then((response) => {
            if (response.data.length) {
                setCaseData(response.data[0])
                setCaseItems(response.data[0].items)
            }
            
        });

        axios.get(`${props.itemCategoriesURL}`).then((response) => {
            setItemCategories(response.data);
        });


    }, []);


    return <Fragment>

        
        <header className='flex flex-col w-full h-full justify-between md:max-w-3xl items-start' style={{backgroundColor: "var(--green)", minHeight: '150px'}}>

            <div className='flex flex-row items-center justify-between w-full p-4'>
                <div className="flex items-center">
                    <span className="text-white mr-2"><CaseIcon size="sm"/></span>
                    <h1 className="capitalize text-2xl text-white">case</h1> 
                </div>
                <p className="text-white">Sold: ${caseData.soldAmount}</p>

            </div>

            <div className="ml-7">
                <p className="mb-4 text-white uppercase">{props.sessionUser.username}</p>
            </div>

        </header>

        <section className="flex flex-col px-4 w-full md:max-w-3xl mb-10">
            
        <Fragment>



        <Fragment>
            <SectionHeader name='contents' />
                {
                    itemCategories.map((category) => 
                        <Fragment>
                            <SectionHeaderSmall name={category.name} />

                            <Fragment>
                                {caseItems.map((item) => {
                                
                                    { return item.category === category.name && (item.Case_Item.quantity > 0 || item.Case_Item.sold > 0) ?
                                        <ItemSmallInCase key={item.id} itemData={{...item}}/>
                                    :
                                    null
                                    }}
                                )}
                            </Fragment>
                        </Fragment>
                    )
                }
        </Fragment>


</Fragment>

        </section>

    </Fragment>
}

export default CaseRunner