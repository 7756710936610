import { Fragment, useEffect, useState } from "react"
import axios from "axios"

import SectionHeader from "../components/Layout/SectionHeader"
import ModIcon from "../components/Icons/ModIcon"



const Admin = (props) => {

    const [userData, setUserData] = useState([]);

    useEffect(() => {
        let userID = window.location.pathname.split("/").pop();
    
        axios.get(`${props.usersURL}/${userID}`).then((response) => {
            setUserData(response.data)
        });
    
    }, []);

    return <Fragment>

        <header className='flex flex-col w-full md:max-w-3xl items-start mb-12' style={{minHeight: '150px', backgroundColor: "var(--green)"}}>
            <div className='flex flex-col w-full p-4'>
                <div className="flex items-center">
                    <span className="text-white mr-2"><ModIcon size="sm" /></span>
                    <h1 className="capitalize text-2xl text-white">admin</h1>
                </div>
                <div>
                <h1 className="text-lg text-white uppercase pl-14">{userData.username}</h1>
                </div>
            </div>
            
        </header>

        <section className="flex flex-col px-4 w-full md:max-w-3xl">

            <a className="btn btn-red uppercase">deactivate account</a>

        </section>

    </Fragment>
}

export default Admin;