const CaseIcon = props => {
    return (

        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        height="48" 
        width="48"
        fill={props.color ? props.color :  'currentColor'}
        viewBox={props.size ? "-12 -18 80 80" : "0 0 48 48"}
        >
            <path d="M7 42q-1.2 0-2.1-.9Q4 40.2 4 39V15q0-1.2.9-2.1.9-.9 2.1-.9h9V7q0-1.2.9-2.1.9-.9 2.1-.9h10q1.2 0 2.1.9.9.9.9 2.1v5h9q1.2 0 2.1.9.9.9.9 2.1v24q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h34V15H7v24Zm12-27h10V7H19ZM7 39V15v24Z"/>
        </svg>
    );
};

export default CaseIcon;