import { Fragment } from "react";

import { Link } from "react-router-dom";

import CaseIcon from "../Icons/CaseIcon";
import InventoryIcon from "../Icons/InventoryIcon";
import CostIcon from "../Icons/CostIcon";

const ItemSmallInCase = (props) => {

    return <div className="flex flex-col pb-2 mb-2">
        
        {
            props.itemData.Case_Item.quantity > 0 ?
                <Link className="flex items-center mr-4" to={`/view-item/${props.itemData.id}`}>
                    <div className="bg-gray-300 w-16 h-16 bg-cover rounded mr-2" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/uploaded_images/item_placeholder.jpg)`}}></div>
                    <div>
                        <div><p className="text-sm font-bold capitalize">{props.itemData.name}</p></div>
                        <div className="flex items-center">
                            <div className="flex items-center">
                                <CaseIcon size="sm"/>
                                <p className="text-xs">x{props.itemData.Case_Item.quantity}</p>
                            </div>

                            <div className="flex items-center">
                                <CostIcon size="sm"/>
                                <p className="text-xs font-bold capitalize">{props.itemData.customCost ? `$${props.itemData.customCost}` : `${props.itemData.subItemCategory.name} $${props.itemData.subItemCategory.cost}`}</p>
                            </div>
                        </div>
                    </div>
                </Link>
            :
            <Link className="flex items-center mr-4 opacity-50" to={`/view-item/${props.itemData.id}`}>
                    <div className="bg-gray-300 w-16 h-16 bg-cover rounded mr-2" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/uploaded_images/item_placeholder.jpg)`}}></div>
                    <div>
                        <div><p className="text-sm font-bold capitalize">{props.itemData.name}</p></div>
                        <div className="flex items-center">
                            <div className="flex items-center">
                                <CaseIcon size="sm"/>
                                <p className="text-xs">x{props.itemData.Case_Item.quantity}</p>
                            </div>

                            <div className="flex items-center">
                                <CostIcon size="sm"/>
                                <p className="text-xs font-bold capitalize">{props.itemData.customCost ? `$${props.itemData.customCost}` : `${props.itemData.subItemCategory.name} $${props.itemData.subItemCategory.cost}`}</p>
                            </div>
                        </div>
                        
                    </div>
                </Link>

        }


    </div>
}

export default ItemSmallInCase;