import { Fragment, useState } from "react"

import { useHistory } from "react-router-dom";
import axios from "axios";

import Input from "../components/Form/Input";


const AddPerson = (props) => {

    const history = useHistory();
    const [errorMsg, setErrorMsg] = useState('');
    const [personTypeSelection, setPersonTypeSelection] = useState('client');

    let handleUserSubmit = (e) => {
        e.preventDefault();

        let personType = document.querySelector('#person-type');
        let username = document.querySelector('#username');
        let password = document.querySelector('#password');

        if (!username.value || !password.value) {
            setErrorMsg('Failed! Missing ID or Password.')
            return;
        }

        personType.style.borderColor = null;
        password.style.borderColor = null;
        username.style.borderColor = null;

        axios.post(props.signUpURL, {
            username: username.value.toLowerCase(),
            password: password.value.toLowerCase(),
            roles: [personType.options[personType.selectedIndex].value]
        }).then((response) => {
            history.push(`/${personType.options[personType.selectedIndex].value}/${response.data.user}`);

        }).catch(function (error) {

            let message = error.response.data.message;

            if (message) {
                setErrorMsg(message)
            } else {
                setErrorMsg('Unknown Error')
            }

        });

    }

    let handleClientSubmit = (e) => {
        e.preventDefault();

        let personType = document.querySelector('#person-type');
        let username = document.querySelector('#username');

        if (!username.value) {
            setErrorMsg('Failed! Missing ID.')
            return;
        }
        username.style.borderColor = null;

        axios.post(props.clientsURL, {
            username: username.value.toLowerCase(),
        }).then((response) => {
            history.push(`/${personType.options[personType.selectedIndex].value}/${response.data.id}`);

        }).catch(function (error) {

            let message = error.response.data.message;

            if (message) {
                setErrorMsg(message)
            } else {
                setErrorMsg('Unknown Error')
            }

        });

    }

    let handlePersonTypeChange = (e) => {
        setPersonTypeSelection(e.target.options[e.target.selectedIndex].value)
        setErrorMsg()
    }

    return <Fragment>

        <header className='flex flex-row w-full md:max-w-3xl border-b-2 items-start mb-12' style={{minHeight: '150px'}}>
            <div className='flex flex-row items-center justify-between w-full p-4'>
                <div className="flex items-center">
                    <h1 className="capitalize text-2xl font-bold text-black">Add Person</h1> 
                </div>
            </div>
        </header>

        <section className="flex flex-col px-4 w-full md:max-w-3xl">

            { errorMsg ?
            <div className="flex justify-center w-full border-2 border-red-300 rounded-md bg-red-100 mt-8 p-4">
                <p className="text-red-500 mb-1">{errorMsg}</p>
            </div>
            :
            null}
            
        
            {personTypeSelection === 'client' ? 

                <form className="flex flex-col" onSubmit={handleClientSubmit}>

                <p className="text-xs text-gray-500 mb-1">Type</p>

                <select name="person-type" id="person-type" onChange={handlePersonTypeChange}>
                    <option value="client">Client</option>
                    <option value="user">Runner</option>
                    <option value="moderator">Moderator</option>
                    <option value="admin">Admin</option>
                </select>

                <p className="text-xs text-gray-500 mb-1">ID</p>
                <Input id="username" />

                

                <button className="btn btn-green" type="submit">Add Person</button>

                </form>

            :

            <form className="flex flex-col" onSubmit={handleUserSubmit}>

                <p className="text-xs text-gray-500 mb-1">Type</p>

                <select name="person-type" id="person-type" onChange={handlePersonTypeChange}>
                    <option value="client">Client</option>
                    <option value="user">Runner</option>
                    <option value="moderator">Moderator</option>
                    <option value="admin">Admin</option>
                </select>

                <p className="text-xs text-gray-500 mb-1">ID</p>
                <Input id="username" />

                <p className="text-xs text-gray-500 mb-1">Password</p>
                <Input id="password" />
                

                <button className="btn btn-green" type="submit">Add Person</button>

            </form>

            }

        </section>

    </Fragment>
}

export default AddPerson;