import React, {useState, useEffect} from "react";

import { useHistory } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";

const EditItemForm = (props) => {
    const history = useHistory()

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    mode: "onChange"
  });

  const [itemCategories, setItemCategories] = useState([])
  const [subItemCategories, setSubItemCategories] = useState([])
  const [itemTypes, setItemTypes] = useState([])

  const handleEditItem = (data) => {
    if (props.new) {
        axios.post(props.itemsURL, {
            name: data.name,
            quantity: data.quantity,
            category: data.category,
            subCategory: data.subCategory,
            customCost: data.customCost,
            itemType: data.itemType,
            note: data.note,
            description: data.description
        }).then((response) => {
            history.push(`/view-item/${response.data.id}`);
        })
    } else {
        axios.put(`${props.itemsURL}/${props.itemData.id}`, {
            name: data.name,
            quantity: data.quantity,
            category: data.category,
            subCategory: data.subCategory,
            customCost: data.customCost,
            itemType: data.itemType,
            note: data.note,
            description: data.description
        }).then((response) => {
            history.push(`/view-item/${response.data.id}`);
        })
    }
};

  const handleError = (errors) => {};

  const registerOptions = {
    name: { required: "Name is required" },
    quantity: { required: "Quantity is required" },
    category: { required: "Category is required" },
    subCategory: { required: "Sub Category is required" },
    customCost: { defaultValue: 0 }
  };

  useEffect(() => {
        if (props.itemData) {
            reset({
                name: props.itemData.name, 
                quantity: props.itemData.quantity, 
                category: props.itemData.category, 
                subCategory: props.itemData.subCategory,
                customCost: props.itemData.customCost,
                itemType: props.itemData.itemType,
                note: props.itemData.note,
                description: props.itemData.description,
                
            });
        }

        axios.get(props.itemCategoriesURL).then((response) => {
            setItemCategories(response.data)
        })

        axios.get(props.subItemCategoriesURL).then((response) => {
            setSubItemCategories(response.data)
        })

        axios.get(props.itemTypesURL).then((response) => {
            setItemTypes(response.data)
        })

    }, [props.itemData]);

  return (
    <form onSubmit={handleSubmit(handleEditItem, handleError)}>

      <div className="mb-0">
        <p className="text-xs text-gray-500 capitalize">Name</p>
        <input name="name" className="input" 
            type="text" 
            {...register('name', registerOptions.name) }
        />
        <small className="text-danger">
          {errors?.name && errors.name.message}
        </small>
      </div>

      <div className="mb-6">
        <p className="text-xs text-gray-500 capitalize">note</p>
        <input name="note" className="input" 
            type="text" 
            {...register('note', registerOptions.note) }
        />
      </div>

      <div>
      <p className="text-xs text-gray-500 capitalize">quantity</p>
        <input
          type="number"
          name="quantity"
          className="input"
          {...register('quantity', registerOptions.quantity)}
        />
        <small className="text-danger">
          {errors?.quantity && errors.quantity.message}
        </small>
      </div>

      <div className="mb-6">
        <p className="text-xs text-gray-500 capitalize">Category</p>
        <select 
            className="w-full uppercase"
            {...register('category', registerOptions.category)}
            >
            {
                itemCategories.map((item) => <option className="w-full uppercase" value={item.name}>{item.name}</option>)
            }
        </select>
      </div>

      <div className="mb-6">
        <p className="text-xs text-gray-500 capitalize">Custom Cost</p>
        <p className="text-xs text-gray-400 capitalize">Overrides Sub Category Cost</p>
        <input 
        type="number"
          name="customCost"
          className="input"
          {...register('customCost', registerOptions.customCost, {
            defaultValue: 0
          })} />
      </div>

      <div className="mb-6">
        <p className="text-xs text-gray-500 capitalize">Sub Category</p>
        <select 
            className="w-full uppercase"
            {...register('subCategory', registerOptions.subCategory)}
            >
            {
                subItemCategories.map((item) => <option className="w-full uppercase" key={item.id} value={item.id}>{item.name} - ${item.cost}</option>)
            }
        </select>
      </div>

      <div className="mb-6">
        <p className="text-xs text-gray-500 capitalize">type</p>
        <select 
            className="w-full uppercase"
            {...register('itemType', registerOptions.itemType)}
            >
            {
                itemTypes.map((item) => <option className="w-full uppercase" key={item.id} value={item.name}>{item.name}</option>)
            }
        </select>
      </div>

      <div className="mb-6">
        <p className="text-xs text-gray-500 capitalize">description</p>
        <input name="description" className="input" 
            type="text" 
            {...register('description', registerOptions.description) }
        />
      </div>

      <button className="btn btn-green w-full">Save</button>

    </form>
  );
};

export default EditItemForm;