import { Fragment, useState, useEffect } from "react"

import { Link } from "react-router-dom";
import axios from "axios";

import HomeIcon from '../components/Icons/HomeIcon';
import GetCases from "../components/Cases/GetCases";
import SectionHeader from '../components/Layout/SectionHeader';
import OrderCard from "../components/Orders/OrderCard";

const Home = (props) => {

    const [cases, setCases] = useState([]);
    const [users, setUsers] = useState([]);
    const [runnersWithCases, setRunnersWithCases] = useState([]);
    const [orders, setOrders] = useState([]);

    useEffect(() => {

        axios.get(props.casesURL).then((response) => {
            setCases(response.data);
        });

        axios.get(props.usersURL).then((response) => {
            setUsers(response.data);
            let runners = []
            response.data.forEach(user => {
                if (user.roles[0].name === 'user') {
                    runners.push(user)
                }
            })
            setRunnersWithCases(runners)
        });

        axios.get(`${props.ordersURL}/unassigned`).then((response) => {
            setOrders(response.data);
        });

    }, []);

    const allCases = cases.map(
        activeCase => 
            activeCase.moderatorId && 
            activeCase.runnerId && 
            activeCase.moderatorId !== 1 && 
            activeCase.runnerId !== 1 && 
            activeCase.cashedOutConfirmed === false ? <GetCases sessionUser={props.sessionUser} key={activeCase.id} casesURL={props.casesURL} usersURL={props.usersURL} case={activeCase} users={users}/> : null
    )

    let activeCases = [];
    for (const theCase of allCases) {
        if (theCase != null) {
            activeCases.push(theCase)
        }
    }



    return <Fragment>

        <header className='flex flex-col w-full h-full justify-between md:max-w-3xl items-start' style={{backgroundColor: "var(--green)", minHeight: '150px'}}>
            <div className='flex flex-row items-center justify-between w-full p-4'>
                <div className="flex items-center">
                    <span className="text-white mr-2"><HomeIcon size="sm"/></span>
                    <h1 className="capitalize text-2xl text-white">dashboard</h1> 
                </div>

                <div>
                    <Link to="/logout" className="btn btn-white text-white capitalize">logout</Link>
                </div>
            </div>

            <div className="ml-7">
                <p className="mb-4 text-white uppercase">{props.sessionUser.username}</p>
            </div>

        </header>


        <section className="flex flex-col px-4 w-full md:max-w-3xl mb-20">
            {
                orders.length ?
                    <SectionHeader name='unassigned orders' buttonLink="add-order" buttonLabel='add'/>
                :
                    <SectionHeader name='orders' buttonLink="add-order" buttonLabel='add'/>
            }

            {orders.map(order => 
                {return <OrderCard key={order.id} {...order} runners={runnersWithCases}/>}
            )}
            
            <Link to='/orders' className="btn btn-green capitalize">view current orders</Link>

            <div className="border-b-2 pt-8"></div>
            
            {
            activeCases.length ? 
                <Fragment>
                    <SectionHeader name='active cases' />
                    {activeCases}
                </Fragment>
            :
                <Fragment>
                    <SectionHeader name='no active cases' />
                    <Link to="/add-case" className="btn btn-green">
                        add case
                    </Link>
                </Fragment>
            }

        </section>

    </Fragment>
}

export default Home