import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import HomeIcon from '../Icons/HomeIcon';
import ClientIcon from '../Icons/ClientIcon';
import CaseIcon from '../Icons/CaseIcon';
import ShoppingIcon from '../Icons/ShoppingIcon';
import InventoryIcon from '../Icons/InventoryIcon';

import classes from './NavBar.module.css'

const NavBar = (props) => {

    return <div className="fixed object-bottom flex bg-gray-200 bottom-0 px-4 w-full justify-between md:max-w-3xl py-2 z-10">
           
            
            {
                props.sessionUser && (props.sessionUser.roles[0] === 'admin' || props.sessionUser.roles[0] === 'moderator')  ?
                    <Fragment>
                        <NavLink className="opacity-50" activeClassName={classes['nav-active']} to='/home'>
                            <HomeIcon size="sm"/>
                        </NavLink>
                        
                        <NavLink className="opacity-50" activeClassName={classes['nav-active']} to='/people'>
                            <ClientIcon size="sm"/>
                        </NavLink>

                        <NavLink className="opacity-50" activeClassName={classes['nav-active']} to='/cases'>
                            <CaseIcon size="sm"/>
                        </NavLink>

                        <NavLink className="opacity-50" activeClassName={classes['nav-active']} to='/transactions'>
                            <ShoppingIcon size="sm"/>
                        </NavLink>

                        <NavLink className="opacity-50" activeClassName={classes['nav-active']} to='/inventory'>
                            <InventoryIcon size="sm"/>
                        </NavLink>
                    </Fragment>
                :
                    <Fragment>
                        <NavLink className="opacity-50" activeClassName={classes['nav-active']} to='/home'>
                            <HomeIcon size="sm"/>
                        </NavLink>

                        <NavLink className="opacity-50" activeClassName={classes['nav-active']} to='/cases'>
                            <CaseIcon size="sm"/>
                        </NavLink>
                    </Fragment>
            }
            
        </div>
}

export default NavBar;