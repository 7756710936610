import { Fragment, useState, useEffect } from "react"

import { useHistory } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";

import Dropdown from "../components/Form/Dropdown";
import RunnerIcon from "../components/Icons/RunnerIcon";
import ClientIcon from "../components/Icons/ClientIcon";
import ShoppingIcon from "../components/Icons/ShoppingIcon";


const AddOrder = (props) => {
    const history = useHistory()

    // const [caseData, setCaseData] = useState([]);
    const [order, setOrder] = useState([]);
    const [clients, setClients] = useState([]);
    const [runners, setRunners] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [convertedDataTime, setConvertedDataTime] = useState(null)

    let handleNewOrderSubmit = (e) => {
        e.preventDefault();
    
        let clientSelect = document.querySelector('#client-select');
        let runnerSelect = document.querySelector('#runner-select');
        let note = document.querySelector('#order-note');
        let scheduledDateSelect = document.querySelector('#schedule-date');

        let clientValue = clientSelect.options[clientSelect.selectedIndex].value
        if (clientValue === '0') {
            setErrorMsg('No Client selected')
            return false
        }
        let runnerValue = runnerSelect.options[runnerSelect.selectedIndex].value
        if (runnerValue === '0') {
            runnerValue = null
        }
        let noteValue = note.value ? note.value : null
        let scheduledDateValue = scheduledDateSelect.value ? scheduledDateSelect.value : null;
    
        axios.post(props.ordersURL, {
            clientId: clientValue,
            runnerId: runnerValue,
            note: noteValue,
            scheduled: scheduledDateValue
        }).then((response) => {

            history.push(`/orders`);
    
        }).catch(function (error) {
            let message = error.response.data.message;
    
            if (message) {
                setErrorMsg(message)
            } else {
                setErrorMsg('Unknown Error')
            }
    
        });
    
    }

    let handleUpdateOrderSubmit = (e) => {
        e.preventDefault();
    
        let runnerSelect = document.querySelector('#runner-select');
        let note = document.querySelector('#order-note');
        let scheduledDateSelect = document.querySelector('#schedule-date');

        let clientValue = order.clientId
        let runnerValue = runnerSelect.options[runnerSelect.selectedIndex].value
        if (runnerValue === '0') {
            runnerValue = null
        }
        let noteValue = note.value ? note.value : null
        let scheduledDateValue = scheduledDateSelect.value ? scheduledDateSelect.value : null;

        axios.put(`${props.ordersURL}/${order.id}`, {
            clientId: clientValue,
            runnerId: runnerValue,
            note: noteValue,
            scheduled: scheduledDateValue
        }).then((response) => {

            history.push(`/orders/`);
    
        }).catch(function (error) {
            let message = error.response.data.message;
    
            if (message) {
                setErrorMsg(message)
            } else {
                setErrorMsg('Unknown Error')
            }
    
        });
    
    }

    let handleDateTimeChange = (e) => {
        setConvertedDataTime(e.target.value)
    }

    useEffect(() => {

        if (props.edit === true) {
            let orderId = window.location.pathname.split("/").pop();

            axios.get(`${props.ordersURL}/${orderId}`).then((response) => {
                setOrder(response.data);
                let inputUpdate = document.querySelector('#order-note')
                inputUpdate.value = response.data.note
                setConvertedDataTime(response.data.scheduled.replace('Z', ''))
            });

        }

        if (props.new === true) {

            axios.get(`${props.clientsURL}`).then((response) => {
                setClients(response.data);
            });

        }
        
        axios.get(`${props.usersURL}/users`).then((response) => {
            setRunners(response.data);
        });

    }, []);

    const runnerOptions = runners.map(
        user => user.case ? <option className={`w-full uppercase`} value={user.id}>{user.username}</option> : null
    )

    // const convertedData = order.scheduled.replace('Z', '')

    return <Fragment>

        <header className={`flex flex-row w-full md:max-w-3xl border-b-2 items-start mb-12`}>
            <div className='flex flex-row items-center justify-between w-full p-4'>
                <div className="flex flex-col justify-between w-full">

                    {
                        props.edit === true ? 

                            <div className="flex items-center">
                                <span className="text-black mr-2"><ShoppingIcon size="sm" /> </span><h1 className="capitalize text-2xl text-black">Edit Order # {order.id}</h1> 
                            </div>
                        :
                            null
                    }

                    {
                        props.new === true ? 

                            <div className="flex items-center">
                                <span className="text-black mr-2"><ShoppingIcon size="sm" /> </span><h1 className="capitalize text-2xl text-black">Add New Order</h1>

                            </div>
                                
                            
                        :
                            
                            null
                    }
                    
                </div>
            </div>
        </header>

        <section className="flex flex-col px-4 w-full md:max-w-3xl mb-20">

            {
                errorMsg ?
                <div className="flex justify-center w-full border-2 border-red-300 rounded-md bg-red-100 mb-8 p-4">
                    <p className="text-red-500 mb-1">{errorMsg}</p>
                </div>
                :
                    null
            }

            {
                props.new === true ? 

                    <form className="flex flex-col w-full" onSubmit={handleNewOrderSubmit}>

                        <Dropdown id="client-select" 
                            label="select client" 
                            icon={<ClientIcon size="sm" />} 
                            data={clients} 
                        />

                            <p className="text-xs text-gray-500 mb-1 capitalize">select runner</p>
                            <div className="flex">
                                <RunnerIcon size='sm' />
                                
                                <select id='runner-select' className="w-full uppercase">
                                    {order.runner ?
                                    <option className="w-full uppercase" value={order.runner.id} selected>{order.runner.username}</option> 
                                    :
                                    <option className="w-full uppercase" value="0" selected>—</option> 
                                }
                                
                                    
                                    {runnerOptions}
                                </select>
                            </div>

                        
                        <p className="text-xs text-gray-500 mt-2">Note</p>
                        <input id="order-note" className="input"></input>

                        <p className="text-xs text-gray-500 mt-2">Schedule</p>
                        <input className="input" type="datetime-local" id="schedule-date"
                             />

                        <button type="submit" className="btn btn-green mt-4">Save Order</button>

                    </form>

                : 
                
                    null
                
            }

            {
                props.edit === true ?
            
                    <form className="flex flex-col w-full" onSubmit={handleUpdateOrderSubmit}>

                    {
                        order.client ?
                        <Fragment>
                            <p className="text-xs text-gray-500 mb-1 capitalize">client</p>
                            <div className='flex items-center mr-4 mb-2'>
                                <span><ClientIcon size='sm' /></span><p className="font-bold">{order.client.username}</p>
                            </div>
                        </Fragment>
                        :
                            null

                    }
                    

                            <p className="text-xs text-gray-500 mb-1 capitalize">select runner</p>
                            <div className="flex">

                                <RunnerIcon size='sm' />
                                
                                <select id='runner-select' className="w-full uppercase">
                                    {
                                        order.runner ?
                                            <option className="w-full uppercase" value={order.runner.id} selected>{order.runner.username}</option> 
                                        :
                                            <option className="w-full uppercase" value="0" selected>—</option> 
                                    }
                                
                                    {runnerOptions}

                                </select>
                            </div>

                        <p className="text-xs text-gray-500 mt-2">Note</p>
                        <input id="order-note" className="input"></input>

                        <p className="text-xs text-gray-500 mt-2">Schedule</p>
                        <input className="input" type="datetime-local" id="schedule-date" onChange={handleDateTimeChange} value={convertedDataTime}/>

                        <button type="submit" className="btn btn-green mt-4">Update Order</button>

                        

                    </form>

                :

                    null
            }
            
        </section>

    </Fragment>
}

export default AddOrder;