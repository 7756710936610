import React from 'react';

import { Link } from 'react-router-dom';

import ClientIcon from '../Icons/ClientIcon';
import RewardIcon from '../Icons/RewardIcon';
import NoteIcon from '../Icons/NoteIcon';
import ClockIcon from '../Icons/ClockIcon';
import CalendarIcon from '../Icons/CalendarIcon';
import RunnerIcon from '../Icons/RunnerIcon';

const OrderCard = props => {

    const hasTime = props.time ? props.time : null;
    const hasDate = props.date ? props.date : null;
    const convertedDataTime = new Date(props.updatedAt).toLocaleString() ;

    return <div className="flex flex-col border-b-2 pb-8 mb-8">
        
      
        <p className="text-xs text-gray-500 ml-3">Added: {convertedDataTime}</p>
        <Link to={`/edit-order/${props.id}`}>
                <div className='flex w-full'>

                    <div className='flex items-center mr-4'>
                        <span><ClientIcon size='sm' /></span><p className="font-bold">{props.client.username}</p>
                    </div>

                    <div className='flex items-center'>
                        <span className="text-blue-500"><RewardIcon size='sm' /></span>
                        {
                            props.client.transactionsRemaining ?
                            <p className='text-xs text-blue-500'>{`${props.client.transactionsRemaining} Transactions Remain`}</p>
                            :
                            <p className='text-xs text-blue-500'>REWARD AVAILABLE</p>
                        }

                    </div>

                </div>
            </Link>

            
        
            {
                props.note ?
                    <div className='flex items-center ml-10'>
                        <span className="text-gray-500"><NoteIcon size='sm' /></span><p className="text-gray-500">{props.note}</p>
                    </div>
                :
                    null
            }
            

            {
                props.runner !== null ?
                    
                        <div className='flex items-center ml-10'>
                            <span><RunnerIcon size='sm' /></span><p className="uppercase">{props.runner.username}</p>
                        </div>
                :
                    <p className='text-red-600 ml-12'>No Runner Assigned</p>
            }

            
            
                {
                    props.scheduled ?
                        <div className='flex items-center ml-9'>
                            <span><CalendarIcon size='sm' /></span>
                            <span className='flex'>
                                <p>{new Date(props.scheduled).toLocaleString()}</p>
                            </span>
                        </div>
                    :
                    null
                }
            

    </div>
}

export default OrderCard;