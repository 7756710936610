const RunnerIcon = props => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            height="48" 
            width="48"
            fill={props.color ? props.color :  'currentColor'}
            viewBox={props.size ? "-12 -18 80 80" : "0 0 48 48"}
        >
            <path d="M26.75 46V34.05l-5.4-4.95-2.1 9.4L6 35.75l.55-2.8 9.95 2 3.65-18.45-5 2.35v6.7h-3V16.8l8.2-3.45q1.6-.7 2.275-.875Q23.3 12.3 24 12.3q1 0 1.775.425.775.425 1.325 1.275l2.1 3.35q1.3 2.1 3.55 3.65Q35 22.55 38 22.55v3q-3.35 0-5.975-1.55-2.625-1.55-4.875-4.65l-1.95 7.9 4.55 4.2V46ZM27 10.3q-1.5 0-2.575-1.075Q23.35 8.15 23.35 6.65q0-1.5 1.075-2.575Q25.5 3 27 3q1.5 0 2.575 1.075Q30.65 5.15 30.65 6.65q0 1.5-1.075 2.575Q28.5 10.3 27 10.3Z"/>
        </svg>
    );
};

export default RunnerIcon;