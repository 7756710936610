import { useEffect } from "react"
import { Redirect} from "react-router-dom";

const Logout = props => {

    useEffect(() => {
        props.handleLogout()
    },[])

    

    return <Redirect to="/" replace />;
      
}

export default Logout