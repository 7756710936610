import { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom"

import axios from "axios";

import CaseIcon from '../components/Icons/CaseIcon';
import SectionHeader from "../components/Layout/SectionHeader";
import GetCases from "../components/Cases/GetCases";

const Cases = (props) => {

    const [activeCases, setActiveCases] = useState([]);
    const [unassignedCases, setUnassignedCases] = useState([]);
    const [closingOut, setClosingOut] = useState([]);

    useEffect(() => {

        axios.get(`${props.casesURL}/active`).then((response) => {
            setActiveCases(response.data);
        });

        axios.get(`${props.casesURL}/unassigned`).then((response) => {
            setUnassignedCases(response.data);
        });

    }, []);

    return <Fragment>

        <header className='flex flex-col w-full h-full justify-between md:max-w-3xl items-start' style={{backgroundColor: "var(--green)", minHeight: '150px'}}>

            <div className='flex flex-row items-center justify-between w-full p-4'>
                <div className="flex items-center">
                    <span className="text-white mr-2"><CaseIcon size="sm"/></span>
                    <h1 className="capitalize text-2xl text-white">cases</h1> 
                </div>

                <div>
                    <Link to="/add-case" className="btn btn-white">add</Link>
                </div>
            </div>

            <div className="ml-7">
                <p className="mb-4 text-white uppercase">{props.sessionUser.username}</p>
            </div>

        </header>

        <section className="flex flex-col px-4 w-full md:max-w-3xl mb-10">

            {
                unassignedCases.length ? 
                    <Fragment>
                    <SectionHeader name='unassigned' />
                        {unassignedCases.map(theCase => 
                            <GetCases key={theCase.id} sessionUser={props.sessionUser} usersURL={props.usersURL} case={theCase}/>
                        )}
                    </Fragment>
                :
                    null
            }
            
            <SectionHeader name='active' />
            {
                activeCases.length ? 
                    <Fragment>
                        {activeCases.map(theCase => 
                            <GetCases key={theCase.id} sessionUser={props.sessionUser} usersURL={props.usersURL} case={theCase}/>
                        )}
                    </Fragment>
                :    
                    <p className="text-red-500 ml-10">No Active Cases</p>
            }

        </section>

    </Fragment>
}

export default Cases