import { Fragment, useState ,useEffect } from 'react';
import {Route, Redirect} from 'react-router-dom';

import NavBar from './components/Layout/NavBar';
import Login from './Pages/Login';
import Logout from './Pages/Logout';
import Home from './Pages/Home';
import HomeRunner from './Pages/HomeRunner';
import People from './Pages/People';
import AddPerson from './Pages/AddPerson';
import Admin from './Pages/Admin';
import Moderator from './Pages/Moderator';
import User from './Pages/User';
import Client from './Pages/Client';
import Cases from './Pages/Cases';
import CaseRunner from './Pages/CaseRunner';
import Transactions from './Pages/Transactions';
import Inventory from './Pages/Inventory';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import AddCase from './Pages/AddCase';
import AddItem from './Pages/AddItem';
import Orders from './Pages/Orders'
import AddOrder from './Pages/AddOrder';
import MakeTransaction from './Pages/MakeTransaction';
import AddTransaction from './Pages/AddTransaction';
import InitializeCloseOut from './components/Cases/InitializeCloseOut';
import ConfirmCashOut from './components/Cases/ConfirmCashOut';

const config = require("./config/frontend.config");

const baseAPI = `${config.DOMAIN}/api`;
const signInURL = `${baseAPI}/auth/signin`;
const signUpURL = `${baseAPI}/auth/signup`;
const usersURL = `${baseAPI}/users`;
const clientsURL = `${baseAPI}/clients`;
const casesURL = `${baseAPI}/cases`;
const itemsURL = `${baseAPI}/items`;
const itemCategoriesURL = `${baseAPI}/itemCategories`;
const subItemCategoriesURL = `${baseAPI}/subItemCategories`;
const itemTypesURL = `${baseAPI}/itemTypes`;
const ordersURL = `${baseAPI}/orders`;
const transactionURL = `${baseAPI}/transactions`;
const imageUploadURL = `${baseAPI}/image-upload/`;

// TODO: Move protected route logic out of components
// Currently using localStorage and useHistory - not persistant through refreshes.

function App() {
  const [sessionUser, setSessionUser] = useState(null);

  useEffect(() => {
    setSessionUser(getSessionUser())
  }, [])

  const handleLogin = (data) => {
    setSessionUser(data.data)
  };

  const getSessionUser = () => {
    return JSON.parse(localStorage.getItem('user'))
  }

  const handleLogout = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    setSessionUser(null)
  };


  return (

    <Fragment>

    <main className='page flex flex-col items-center pb-20'>

      <Route exact path='/'>
        <Login signInUrl={signInURL} handleLogin={handleLogin}/>
      </Route>

      <Route path='/logout'>
        <Logout handleLogout={handleLogout}/>
      </Route>

      <Route path='/home'>
        <ProtectedRoute getSessionUser={getSessionUser}>
          <NavBar sessionUser={sessionUser}/>

            {
              sessionUser && (sessionUser.roles[0] === 'admin' || sessionUser.roles[0] === 'moderator')  ?
                <Home sessionUser={sessionUser} handleLogout={handleLogout} usersURL={usersURL} casesURL={casesURL} ordersURL={ordersURL} />
              :
                  null
            } 
          
          

          {
            sessionUser && sessionUser.roles[0] === 'user' ?
              <HomeRunner sessionUser={sessionUser} handleLogout={handleLogout} usersURL={usersURL} casesURL={casesURL} ordersURL={ordersURL} />
            :
                null
          }
          
        </ProtectedRoute>
      </Route>

      <Route path='/orders'>
        <ProtectedRoute getSessionUser={getSessionUser}>
          <NavBar sessionUser={sessionUser}/>
          <Orders ordersURL={ordersURL} />
        </ProtectedRoute>
      </Route>

      <Route path='/add-order'>
        <ProtectedRoute getSessionUser={getSessionUser}>
          <NavBar sessionUser={sessionUser}/>
          <AddOrder 
            new={true} 
            edit={false}
            ordersURL={ordersURL}
            usersURL={usersURL} 
            clientsURL={clientsURL}
          />
        </ProtectedRoute>
      </Route>

      <Route path='/edit-order'>
        <ProtectedRoute getSessionUser={getSessionUser}>
          <NavBar sessionUser={sessionUser}/>
          <AddOrder 
            new={false} 
            edit={true}
            casesURL={casesURL} 
            usersURL={usersURL} 
            ordersURL={ordersURL}
          />
        </ProtectedRoute>
      </Route>

      <Route path='/people'>
        <ProtectedRoute getSessionUser={getSessionUser}>
          <NavBar sessionUser={sessionUser}/>
          {
              sessionUser  ?
              <People sessionUser={sessionUser} usersURL={usersURL} clientsURL={clientsURL}/>
              :
                  null
            } 
          
        </ProtectedRoute>
      </Route>

      <Route path='/add-person'>
        <ProtectedRoute getSessionUser={getSessionUser}>
          <NavBar sessionUser={sessionUser}/>
          <AddPerson signUpURL={signUpURL} clientsURL={clientsURL}/>
        </ProtectedRoute>
      </Route>

      <Route path='/admin'>
        <ProtectedRoute getSessionUser={getSessionUser}>
          <NavBar sessionUser={sessionUser}/>
          <Admin usersURL={usersURL}/>
        </ProtectedRoute>
      </Route>

      <Route path='/moderator'>
        <ProtectedRoute getSessionUser={getSessionUser}>
          <NavBar sessionUser={sessionUser}/>
          <Moderator usersURL={usersURL}/>
        </ProtectedRoute>
      </Route>

      <Route path='/user'>
        <ProtectedRoute getSessionUser={getSessionUser}>
          <NavBar sessionUser={sessionUser}/>
          <User usersURL={usersURL} sessionUser={sessionUser} ordersURL={ordersURL}/>
        </ProtectedRoute>
      </Route>

      <Route path='/client'>
        <ProtectedRoute getSessionUser={getSessionUser}>
          <NavBar sessionUser={sessionUser}/>
          <Client transactionURL={transactionURL} clientsURL={clientsURL}/>
        </ProtectedRoute>
      </Route>

      <Route path='/cases'>
      <ProtectedRoute getSessionUser={getSessionUser}>
        <NavBar sessionUser={sessionUser}/>
        {
            sessionUser && (sessionUser.roles[0] === 'admin' || sessionUser.roles[0] === 'moderator')  ?
              <Cases sessionUser={sessionUser} usersURL={usersURL} casesURL={casesURL} />
            :
              null
          }

{
            sessionUser && sessionUser.roles[0] === 'user' ?
            <CaseRunner sessionUser={sessionUser} itemCategoriesURL={itemCategoriesURL} usersURL={usersURL} casesURL={casesURL}/>
            :
              null
          }
        
        </ProtectedRoute>
      </Route>

      <Route path='/add-case'>
        <ProtectedRoute getSessionUser={getSessionUser}>
          <NavBar sessionUser={sessionUser}/>
          <AddCase 
            new={true} 
            edit={false}
            casesURL={casesURL} 
            usersURL={usersURL} 
            itemCategoriesURL={itemCategoriesURL} 
            itemsURL={itemsURL}
          />
        </ProtectedRoute>
      </Route>

      <Route path='/edit-case'>
        <ProtectedRoute getSessionUser={getSessionUser}>
          <NavBar sessionUser={sessionUser}/>
          <AddCase 
            new={false} 
            edit={true}
            casesURL={casesURL} 
            usersURL={usersURL} 
            itemCategoriesURL={itemCategoriesURL} 
            itemsURL={itemsURL}
          />
        </ProtectedRoute>
      </Route>

      <Route path='/start-close-out'>
      <ProtectedRoute getSessionUser={getSessionUser}>
        <NavBar sessionUser={sessionUser}/>
          <InitializeCloseOut 
            sessionUser={sessionUser} casesURL={casesURL} usersURL={usersURL} transactionURL={transactionURL}
          />
        </ProtectedRoute>
      </Route>

      <Route path='/confirm-cash-out'>
      <ProtectedRoute getSessionUser={getSessionUser}>
        <NavBar sessionUser={sessionUser}/>
          <ConfirmCashOut 
            sessionUser={sessionUser} casesURL={casesURL} usersURL={usersURL} transactionURL={transactionURL}
          />
        </ProtectedRoute>
      </Route>

      <Route path='/view-case'>
      <ProtectedRoute getSessionUser={getSessionUser}>
        <NavBar sessionUser={sessionUser}/>
          <AddCase 
            new={false} 
            edit={false} 
            casesURL={casesURL} 
            usersURL={usersURL}
            itemCategoriesURL={itemCategoriesURL} 
          />
        </ProtectedRoute>
      </Route>

      <Route path='/transactions'>
      <ProtectedRoute getSessionUser={getSessionUser}>
        <NavBar sessionUser={sessionUser}/>
        {
            sessionUser  ?
            <Transactions sessionUser={sessionUser} transactionURL={transactionURL}/>
            :
              null
          }

        
        </ProtectedRoute>
        
      </Route>

      <Route path='/make-transaction'>
      <ProtectedRoute getSessionUser={getSessionUser}>
        <NavBar sessionUser={sessionUser}/>

        <MakeTransaction casesURL={casesURL} itemCategoriesURL={itemCategoriesURL} transactionURL={transactionURL}/>
        </ProtectedRoute>
        
      </Route>

      <Route path='/view-transaction'>
      <ProtectedRoute getSessionUser={getSessionUser}>
        <NavBar sessionUser={sessionUser}/>

        <AddTransaction transactionURL={transactionURL}/>
        </ProtectedRoute>
        
      </Route>

      <Route path='/inventory'>
        <ProtectedRoute getSessionUser={getSessionUser}>
          <NavBar sessionUser={sessionUser}/>
          {
              sessionUser  ?
              <Inventory sessionUser={sessionUser} itemsURL={itemsURL} itemCategoriesURL={itemCategoriesURL} />
              :
                  null
            } 
          
        </ProtectedRoute>
      </Route>

      <Route path='/view-item'>
        <ProtectedRoute getSessionUser={getSessionUser}>
          <NavBar sessionUser={sessionUser}/>
          <AddItem 
            new={false} 
            edit={false} 
            itemsURL={itemsURL}
            sessionUser={sessionUser}
          />
        </ProtectedRoute>
      </Route>

      <Route path='/edit-item'>
      <ProtectedRoute getSessionUser={getSessionUser}>
          <NavBar sessionUser={sessionUser}/>
          <AddItem 
            new={false} 
            edit={true} 
            itemsURL={itemsURL} 
            itemCategoriesURL={itemCategoriesURL} 
            subItemCategoriesURL={subItemCategoriesURL}
            itemTypesURL={itemTypesURL}
            uploadImageURL={imageUploadURL}
            sessionUser={sessionUser}
          />
        </ProtectedRoute>
      </Route>

      <Route path='/new-item'>
      <ProtectedRoute getSessionUser={getSessionUser}>
          <NavBar sessionUser={sessionUser}/>
          <AddItem 
            new={true} 
            edit={false} 
            itemsURL={itemsURL} 
            itemCategoriesURL={itemCategoriesURL} 
            subItemCategoriesURL={subItemCategoriesURL}
            itemTypesURL={itemTypesURL}
            uploadImageURL={imageUploadURL}
            sessionUser={sessionUser}
          />
        </ProtectedRoute>
      </Route>
      

      </main>
      
    </Fragment>

  );
}

export default App;
