import { Fragment, useEffect, useState } from "react"

import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";

import ShoppingIcon from '../components/Icons/ShoppingIcon';
import ClientIcon from "../components/Icons/ClientIcon";
import RunnerIcon from "../components/Icons/RunnerIcon";
import ItemSmallCase from "../components/Item/ItemSmallCase";
import SectionHeader from "../components/Layout/SectionHeader";
import SectionHeaderSmall from "../components/Layout/SectionHeaderSmall";
import LineItem from "../components/Layout/LineItem";
import RewardIcon from '../components/Icons/RewardIcon';

const MakeTransaction = (props) => {

    const history = useHistory()

    const [runnerCase, setRunnerCase] = useState([]);
    const [itemCategories, setItemCategories] = useState([]);
    const [items, setItems] = useState([]);
    const [itemsToSell, setItemsToSell] = useState([]);
    const [totalCost, setTotalCost] = useState([0]);
    const [subTotalCost, setSubTotalCost] = useState([0]);
    const [ErrorMsg, setErrorMsg] = useState()

    const location = useLocation();
    const { clientData, runnerData, orderId } = location.state;

    useEffect(() => {

        axios.get(`${props.casesURL}/runner/${runnerData.id}`).then((response) => {
            setRunnerCase(response.data[0])
            setItems(response.data[0].items)
        });

        axios.get(`${props.itemCategoriesURL}`).then((response) => {
            setItemCategories(response.data);
        });

    }, [])

    useEffect(() => {
        setSubTotalCost(calcSubTotalCost)
        setTotalCost(calcTotalCost)

    },[itemsToSell])

    let handleMakeTransaction = () => {

        // let formData = new FormData();
        let data = {
            clientId: clientData.id,
            runnerId: runnerData.id,
            caseId: runnerCase.id,
            items: itemsToSell,
            orderId: orderId,
            note: document.querySelector('#transaction-note').value ? document.querySelector('#transaction-note').value : null
        };

        /* for(let dataKey in data) {
            if(dataKey === 'items') {
                formData.append(dataKey, JSON.stringify(data[dataKey]));
            }
            else {
                formData.append(dataKey, data[dataKey]);
            }
        } */



          axios.post(props.transactionURL, {
            clientId: clientData.id,
            runnerId: runnerData.id,
            caseId: runnerCase.id,
            items: JSON.stringify(itemsToSell),
            note: document.querySelector('#transaction-note').value ? document.querySelector('#transaction-note').value : null,
            subTotal: subTotalCost,
            totalCost: totalCost,
            orderId: orderId,
        }).then((response) => {

            history.push(`/home`);
    
        }).catch(function (error) {
            let message = error.response.data.message;
    
            if (message) {
                setErrorMsg(message)
            } else {
                setErrorMsg('Unknown Error')
            }
    
        });

    }

    let calcTotalCost = () => {
        let result = 0;
        
        itemsToSell.forEach(item => {
            let itemCost = item.customCost ? item.customCost : item.cost
            result += itemCost * item.quantity
        })

        return clientData.transactionsRemaining ? result : result - ((10 / 100) * result)
    }

    let calcSubTotalCost = () => {
        let result = 0;
        
        itemsToSell.forEach(item => {
            let itemCost = item.customCost ? item.customCost : item.cost
            result += itemCost * item.quantity
        })

        return result
    }

    return <Fragment>

        <header className='flex flex-col w-full md:max-w-3xl items-start' style={{backgroundColor: "var(--green)", minHeight: '150px'}}>
            <div className='flex flex-row items-center justify-between w-full p-4'>
                <div className="flex items-center">
                    <span className="text-white mr-2"><ShoppingIcon size="sm"/></span>
                    <h1 className="capitalize text-2xl text-white">Checkout</h1> 
                </div>
            </div>
            <div className="flex ml-4">
                <div className='flex items-center mr-4 text-white'>
                    <span><RunnerIcon size='sm' /></span><p className="font-bold">{runnerData.username}</p>
                </div>
                <div className='flex items-center mr-4 text-white'>
                    <span><ClientIcon size='sm' /></span><p className="font-bold">{clientData.username}</p>
                </div>
            </div>
        </header>

        <section className="flex flex-col px-4 w-full md:max-w-3xl mb-20">

            

            <SectionHeader name={'select items'}/>

            {
                itemCategories.map((category) => 
                    <Fragment>

                        <SectionHeaderSmall name={category.name} />

                        <Fragment>

                            {items.map((item) =>
                                { return item.category === category.name ?
                                    <ItemSmallCase key={item.id} itemData={{...item}} caseId={runnerCase.id} handleItemsToSell={[itemsToSell, setItemsToSell]}/>
                                :
                                null
                                }
                            )}

                        </Fragment>

                    </Fragment>
                )
            }

            {
                itemsToSell.length ?

                <Fragment>
                    <div className="border-b mt-8 mb-8"></div>

                    {
                        itemsToSell.map((item) => {
                            return <LineItem key={item.id} {...item}/>
                        })
                    }

                    <div className="flex justify-end mt-8">
                        <p>Sub Total: ${subTotalCost}</p>
                    </div>

                    <div className="border-b mt-8 mb-8"></div>

                    <div className="flex justify-between items-center">

                    {
                        clientData.transactionsRemaining > 0 ?
                        <div className="flex items-center text-blue-500 text-xs"><span><RewardIcon size='sm' /></span> <p>{`${clientData.transactionsRemaining} Transactions Remain`}</p></div>
                        :
                        <div className="flex items-center text-blue-500 text-xs font-bold"><span><RewardIcon size='sm' /></span> <p>REWARD AVAILABLE</p></div>
                    }
                    
                        {
                            clientData.transactionsRemaining === 0 ?
                                <p className="font-bold text-blue-500">-10%</p>
                            :
                                null
                        }
                        

                    </div>

                    <div className="flex flex-col justify-end">
                        <div className="border-b w-[100px] mt-8 mb-8" style={{marginLeft: 'auto'}}></div>
                        <p className="font-bold" style={{marginLeft: 'auto'}}>Total: ${totalCost}</p>
                    </div>

                    <p className="text-sm text-gray-600 mt-8">Transaction Note</p>
                    <input id="transaction-note" className="input"></input>

                    <div onClick={handleMakeTransaction} className="btn btn-green mt-8">confirm transaction</div>

                </Fragment>

                :
                    null
            }


        </section>

    </Fragment>
}

export default MakeTransaction;