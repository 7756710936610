import { useState, useEffect, Fragment } from "react";

import CaseIcon from "../Icons/CaseIcon";
import CostIcon from "../Icons/CostIcon";


const ItemSmallCase = (props) => {

    let [item, setItem] = useState(props.itemData)
    let [quantityInCase, setQuantityInCase] = useState(item.Case_Item.quantity)
    let [sellQuantity, setSellQuantity] = useState(0)

    let increaseSellQuantity = () => {
        if (sellQuantity < item.Case_Item.quantity) {
            setSellQuantity(sellQuantity + 1)
            setQuantityInCase(quantityInCase - 1)
            
            let foundItem = false
            props.handleItemsToSell[0].forEach(sellItem => {
                if (sellItem.id === item.id) {
                    foundItem = true
                }
            })
            if (foundItem) {
                props.handleItemsToSell[1](current =>
                    current.map(obj => {
                      if (obj.id === item.id) {
                        return {...obj, quantity: obj.quantity + 1};
                      }
              
                      return obj;
                    }),
                  );
            } else {
                props.handleItemsToSell[1](current => [...current, {id: item.id, name: item.name, cost: item.subItemCategory.cost, customCost: item.customCost, quantity: 1}]);
            }
            

        }
        
    }

    let reduceSellQuantity = () => {
        if (sellQuantity > 0) {
            setSellQuantity(sellQuantity - 1)
            setQuantityInCase(quantityInCase + 1)

            let foundItem = false
            props.handleItemsToSell[0].forEach(sellItem => {
                if (sellItem.id === item.id) {
                    foundItem = true
                }
            })
            if (foundItem) {
                props.handleItemsToSell[1](current =>
                    current.map(obj => {
                      if (obj.id === item.id) {
                        return {...obj, quantity: obj.quantity - 1};
                      }
              
                      return obj;
                    }),
                  );
            }

            props.handleItemsToSell[1](current =>
                current.filter(obj => {
                  return obj.quantity > 0;
                }),
              );
        }
    }

    return <Fragment>

        <div className={`flex justify-between pb-2 mb-2 ${quantityInCase > 0 || sellQuantity ? 'opacity-100' : 'opacity-50'}`}>
    
            <div className="flex">
                <div className="bg-gray-300 w-16 h-16 bg-cover rounded mr-2" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/uploaded_images/item_placeholder.jpg)`}}></div>

                <div className="flex flex-col">
                    <div><p className="text-sm font-bold">{props.itemData.name}</p></div>






                    <div className="flex items-center">
                            <div className="flex items-center">
                            <CaseIcon size="sm"/>
                        {
                            quantityInCase < 10 ?
                                <p className="text-xs text-red-500 font-bold">x{quantityInCase}</p>
                            :
                                <p className="text-xs">x{quantityInCase}</p>
                        }
                            </div>

                            <div className="flex items-center">
                                <CostIcon size="sm"/>
                                <p className="text-xs font-bold capitalize">{props.itemData.customCost ? `$${props.itemData.customCost}` : `${props.itemData.subItemCategory.name} $${props.itemData.subItemCategory.cost}`}</p>
                            </div>
                        </div>














                </div>
            </div>

            <div className="flex items-center">

                {
                    quantityInCase ?
                    <a onClick={increaseSellQuantity} className="btn btn-green" style={{padding: '.5rem 1rem'}}>+</a>
                    :
                    null

                }
                
                <input className="input" disabled style={{textAlign: 'center', width: '3ch', padding:'.5ch', margin: '0'}} value={sellQuantity} />

                {
                    quantityInCase || sellQuantity ?
                    <a onClick={reduceSellQuantity} className="btn btn-red" style={{padding: '.5rem 1rem'}}>-</a>
                    :
                    null
                }
                    
            </div>

        </div>

    </Fragment>
}

export default ItemSmallCase;