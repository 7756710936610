import { Fragment, useState, useEffect } from "react"
import { Link } from "react-router-dom";

import axios from "axios";

import HomeIcon from '../components/Icons/HomeIcon';
import SectionHeader from '../components/Layout/SectionHeader';
import RunnerOrderCard from "../components/Orders/RunnerOrderCard";

const HomeRunner = (props) => {
    const [orders, setOrders] = useState([]);
    const [scheduled, setScheduled] = useState([]);

    useEffect(() => {

        axios.get(`${props.ordersURL}/runner/${props.sessionUser.id}`).then((response) => {
            setOrders(response.data);

            let getScheduled = []
            let getAsap = []

            for (let order of response.data) {
                if (order.scheduled) {
                    getScheduled.push(order)
                } else {
                    getAsap.push(order)
                }
            }
            setOrders(getAsap)
            setScheduled(getScheduled)
        });

    }, [props.sessionUser]);

    return <Fragment>

        <header className='flex flex-col w-full h-full justify-between md:max-w-3xl items-start' style={{backgroundColor: "var(--green)", minHeight: '150px'}}>

            <div className='flex flex-row items-center justify-between w-full p-4'>
                <div className="flex items-center">
                    <span className="text-white mr-2"><HomeIcon size="sm"/></span>
                    <h1 className="capitalize text-2xl text-white">dashboard</h1> 
                </div>

                <div>
                    <Link to="/logout" className="btn btn-white text-white capitalize">logout</Link>
                </div>
            </div>

            <div className="ml-7">
                <p className="mb-4 text-white uppercase">{props.sessionUser.username}</p>
            </div>

        </header>

        <section className="flex flex-col px-4 w-full md:max-w-3xl">

            {
                orders.length ?
                    <Fragment>
                        <SectionHeader name='ASAP' />
                        {orders.map(order => {
                                return <RunnerOrderCard key={order.id} {...order}/>
                        })}
                    </Fragment>
                :
                    null
            }
            

            {
                scheduled.length ?
                    <Fragment>
                        <SectionHeader name='scheduled' />

                        {scheduled.map(order => {
                            return <RunnerOrderCard key={order.id} {...order}/>
                        })}
                    </Fragment>
                :
                    null
            }


            
            {
                !orders.length && !scheduled.length ?
                    <SectionHeader name='no orders' />
                :
                null
            }





            {/* <SectionHeader name='orders'/>

            {orders.map(order => 
                {return <RunnerOrderCard key={order.id} {...order} runners={orders}/>}
            )}

            {
                !orders.length ?
                <p>No Orders</p>
                :
                null
            } */}

        </section>

    </Fragment>
}

export default HomeRunner;