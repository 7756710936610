import { Fragment, useEffect, useState } from "react"

import axios from "axios"
import { useHistory } from "react-router-dom"

import SectionHeader from "../components/Layout/SectionHeader"
import ModIcon from "../components/Icons/ModIcon"
import MakeCase from "../components/Cases/MakeCase"

const Moderator = (props) => {
    const history = useHistory()

    const [userData, setUserData] = useState([]);
    const [casesData, setCasesData] = useState([]);

    useEffect(() => {
        let userID = window.location.pathname.split("/").pop();
    
        axios.get(`${props.usersURL}/${userID}`).then((response) => {
            setUserData(response.data.user)
            setCasesData(response.data.user.cases)
            
        });
    
    }, []);

    let handleSavePassword = () => {
        let userPassword = document.querySelector('#user-password')
        let userPasswordValue = userPassword.value

        axios.put(`${props.usersURL}/password/${userData.id}`, {
            password: userPasswordValue,
        }).then((response) => {
            userPassword.value = ''
            history.push(`/moderator/${userData.id}`);
        })
    }

    return <Fragment>

    


        <header className='flex flex-col w-full h-full justify-between md:max-w-3xl items-start' style={{backgroundColor: "var(--green)", minHeight: '150px'}}>
            <div className='flex flex-row items-center justify-between w-full p-4'>
                <div className="flex items-center">
                <span className="text-white mr-2"><ModIcon size="sm" /></span>
                    <h1 className="capitalize text-2xl text-white">moderator</h1>
                </div>

            </div>
            <div className="ml-7">
                <p className="mb-4 text-white uppercase">{userData.username}</p>
            </div>
        </header>



        <section className="flex flex-col px-4 w-full md:max-w-3xl mb-20">

            <SectionHeader name='assigned cases' />

            {
                casesData.map(theCase => {
                    return <MakeCase caseData={{...theCase}} />
                })
            }

            <SectionHeader name='settings' />

            <p className="text-sm text-gray-600 capitalize">new password</p>
                        <input id="user-password" className="input"/>

                        <a onClick={handleSavePassword} className="btn btn-red mb-4">save new password</a>


        </section>

    </Fragment>
}

export default Moderator;