import React from "react"

import { Link } from "react-router-dom"

import CaseIcon from "../Icons/CaseIcon"

const MakeCase = (props) => {
    return <Link to={`/view-case/${props.caseData.id}`} className="flex">
            
    <div className='flex items-center justify-between w-full'>

        <div className='flex items-center mr-4'>
            <span><CaseIcon size='sm' /></span><p className="font-bold uppercase">Case #{props.caseData.id}</p>
        </div>

        <div className='flex flex-col items-center'>
            <div><p className="font-bold">Sold: ${props.caseData.soldAmount}</p></div>
        </div>

    </div>

</Link>
}

export default MakeCase;