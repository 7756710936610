import { Fragment, useState, useEffect } from "react"

import { useHistory } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";

import Dropdown from "../components/Form/Dropdown";
import ModIcon from "../components/Icons/ModIcon";
import RunnerIcon from "../components/Icons/RunnerIcon";
import SectionHeader from "../components/Layout/SectionHeader";
import SectionHeaderSmall from "../components/Layout/SectionHeaderSmall";
import CaseIcon from "../components/Icons/CaseIcon";
import ItemSmallAdd from "../components/Item/ItemSmallAdd";
import ItemSmall from "../components/Item/ItemSmall";
import ItemSmallInCase from "../components/Item/inSmallInCase";





const AddCase = (props) => {
    const history = useHistory()

    const [caseData, setCaseData] = useState([]);
    const [runners, setRunners] = useState([]);
    const [moderators, setModerators] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [items, setItems] = useState([]);
    const [caseItems, setCaseItems] = useState([]);
    const [itemCategories, setItemCategories] = useState([]);
    const [categoriesHaveItems, setCategoriesHaveItems] = useState({});

    let handleNewCaseSubmit = (e) => {
        e.preventDefault();
    
        let moderatorSelect = document.querySelector('#moderator-select');
        let runnerSelect = document.querySelector('#runner-select');

        let moderatorValue = moderatorSelect.options[moderatorSelect.selectedIndex].value
        let runnerValue = runnerSelect.options[runnerSelect.selectedIndex].value
    
        axios.post(props.casesURL, {
            moderatorId: moderatorValue,
            runnerId: runnerValue
        }).then((response) => {

            history.push(`/edit-case/${response.data.id}`);
    
        }).catch(function (error) {
            let message = error.response.data.message;
    
            if (message) {
                setErrorMsg(message)
            } else {
                setErrorMsg('Unknown Error')
            }
    
        });
    
    }

    let handleUpdateCaseSubmit = (e) => {
        e.preventDefault();

        // TODO: currently makes a new case, needs to append current case
    
        let moderatorSelect = document.querySelector('#moderator-select');
        let runnerSelect = document.querySelector('#runner-select');

        let moderatorValue = moderatorSelect.options[moderatorSelect.selectedIndex].value
        let runnerValue = runnerSelect.options[runnerSelect.selectedIndex].value
    
        axios.put(`${props.casesURL}/${caseData.id}`, {
            moderatorId: moderatorValue,
            runnerId: runnerValue
        }).then((response) => {

            history.push(`/view-case/${caseData.id}`);
    
        }).catch(function (error) {
            let message = error.response.data.message;
    
            if (message) {
                setErrorMsg(message)
            } else {
                setErrorMsg('Unknown Error')
            }
    
        });
    
    }

    let getUserByPk = (users, pk) => {
        for (const user of users) {
            if (user.id === pk) {
                return user;
            }
        }
        return false;
    }

    useEffect(() => {

        if (props.new === false) {

            let caseId = window.location.pathname.split("/").pop();
    
            axios.get(`${props.casesURL}/${caseId}`).then((response) => {
                setCaseData(response.data)
                setCaseItems(response.data.items)
            });

        }

        if (props.edit === true) {
            axios.get(`${props.itemsURL}`).then((response) => {
                setItems(response.data);
            });
        }
        
        axios.get(`${props.usersURL}/moderators`).then((response) => {
            setModerators(response.data);
        });

        axios.get(`${props.usersURL}/users`).then((response) => {
            setRunners(response.data);
        });

        axios.get(`${props.itemCategoriesURL}`).then((response) => {
            setItemCategories(response.data);
        });

    }, []);

    return <Fragment>

        <header className={`flex flex-col w-full md:max-w-3xl border-b-2 items-start justify-between h-full mb-12 ${props.new === true || props.edit === true ? null : 'bg-[#5DB075]'}`} style={{minHeight: '150px'}}>
            <div className='flex flex-row items-center justify-between w-full p-4'>
                <div className="flex flex-col justify-between w-full">
                    
                    {
                        props.edit === false && props.new === false ? 
                            <div className="flex justify-between">
                                <div>
                                    <div className="flex items-center">
                                        <span className="text-white mr-2"><CaseIcon size="sm" /> </span><h1 className="capitalize text-2xl text-white">Case #{caseData.id}</h1>
                                    </div>
                                </div>
                            
                                <Link to={`/edit-case/${caseData.id}`} className="btn btn-white">Edit</Link>
                            </div>
                        :
                            
                        null
                    }

                    {
                        props.edit === true ? 

                            <div className="flex items-center">
                                <span className="text-black mr-2"><CaseIcon size="sm" /> </span><h1 className="capitalize text-2xl text-black">Edit Case # {caseData.id}</h1> 
                            </div>
                        :
                            null
                    }

                    {
                        props.new === true ? 

                            <div className="flex items-center">
                                <span className="text-black mr-2"><CaseIcon size="sm" /> </span><h1 className="capitalize text-2xl text-black">Add New Case</h1>

                            </div>
                                
                            
                        :
                            
                            null
                    }
                    
                </div>
            </div>

            <div className="pb-4 ml-6">
                <p className="text-white">Sold ${caseData.soldAmount}</p>
            </div>
        </header>

        <section className="flex flex-col px-4 w-full md:max-w-3xl mb-10">

            {
                errorMsg ?
                <div className="flex justify-center w-full border-2 border-red-300 rounded-md bg-red-100 mb-8 p-4">
                    <p className="text-red-500 mb-1">{errorMsg}</p>
                </div>
                :
                    null
            }

            {
                props.new === false && props.edit === false ?

                    <Fragment>

                        <div className="flex items-center">
                            {
                                caseData.moderatorId === 1 ?
                                    <div className='flex items-center mr-4'>
                                        <span><ModIcon size='sm' /></span><p className="uppercase text-red-600">No mod</p>
                                    </div>
                                :
                                <Link to={`/moderator/${caseData.moderatorId}`}>
                                    <div className='flex items-center mr-4'>
                                        <span><ModIcon size='sm' /></span><p className="uppercase">{getUserByPk(moderators, caseData.moderatorId).username}</p>
                                    </div>
                                </Link>
                            }

                            {
                                caseData.runnerId === 1 ?
                                    <div className='flex items-center'>
                                        <span><RunnerIcon size='sm' /></span><p className="uppercase text-red-600">No Runner</p>
                                    </div>
                                :
                                <Link to={`/user/${caseData.runnerId}`}>
                                    <div className='flex items-center'>
                                        <span><RunnerIcon size='sm' /></span><p className="uppercase">{getUserByPk(runners, caseData.runnerId).username}</p>
                                    </div>
                                </Link>
                            }
                        </div>
                        

                        {
                            props.new === false && props.edit === false ?
                                <Fragment>
                                    <SectionHeader name='contents' />
                                        {
                                            itemCategories.map((category) => 
                                                <Fragment>
                                                    <SectionHeaderSmall name={category.name} />

                                                    <Fragment>
                                                        {caseItems.map((item) =>{
                                                        
                                                            { return item.category === category.name && (item.Case_Item.quantity > 0 || item.Case_Item.sold > 0) ?
                                                                <ItemSmallInCase key={item.id} itemData={{...item}}/>
                                                            :
                                                            null
                                                            }}
                                                        )}
                                                    </Fragment>
                                                </Fragment>
                                            )
                                        }
                                </Fragment>
                            :
                                null
                        }
                        
                    </Fragment>

                :

                    null
            
            }

            {
                props.new === true ? 

                    <form className="flex flex-col w-full" onSubmit={handleNewCaseSubmit}>

                        <Dropdown id="moderator-select" 
                            label="select moderator" 
                            icon={<ModIcon size="sm" />} 
                            data={moderators} 
                        />

                        <Dropdown id="runner-select" 
                            label="select runner" 
                            icon={<RunnerIcon size="sm" />} 
                            data={runners} 
                        />

                        {/* <Dropdown id="default-content-select" 
                            label="premade loadout" 
                            icon={<CaseIcon size="sm" />} 
                            data={[]}
                            defaultOption={[0, 'No']}
                        /> */}

                        

                        <button type="submit" className="btn btn-green mt-4">Save Case</button>

                    </form>

                : 
                
                    null
                
            }

            {
                props.edit === true ?
            
                    <form className="flex flex-col w-full" onSubmit={handleUpdateCaseSubmit}>

                        <Dropdown id="moderator-select" 
                            label="select moderator" 
                            icon={<ModIcon size="sm" />} 
                            data={moderators}
                            defaultOption={
                                caseData.moderatorId && caseData.moderatorId !== 1 ?
                                    [getUserByPk(moderators, caseData.moderatorId).id, getUserByPk(moderators, caseData.moderatorId).username]
                                :
                                    ['1', '—']
                            }
                        />

                        <Dropdown id="runner-select" 
                            label="select runner" 
                            icon={<RunnerIcon size="sm" />} 
                            data={runners}
                            defaultOption={
                                caseData.runnerId && caseData.runnerId !== 1 ?
                                [getUserByPk(runners, caseData.runnerId).id, getUserByPk(runners, caseData.runnerId).username]
                            :
                                ['1', '—']
                            }
                        />

                        <button type="submit" className="btn btn-green mt-4">Update Owners</button>

                        <SectionHeader name={'Add From Storage'}/>

                            {
                                itemCategories.map((category) => 
                                    <Fragment>

                                        <SectionHeaderSmall name={category.name} />

                                        <Fragment>

                                            {items.map((item) =>
                                                { return item.category === category.name ?
                                                    <ItemSmallAdd key={item.id} itemData={{...item}} caseId={caseData.id}/>
                                                :
                                                null
                                                }
                                            )}

                                        </Fragment>

                                    </Fragment>
                                )
                            }

                    </form>

                :

                    null
            }
            
        </section>

    </Fragment>
}

export default AddCase;