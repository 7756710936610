import { useState, useEffect, Fragment } from "react";

import axios from "axios";

import CaseIcon from "../Icons/CaseIcon";
import InventoryIcon from "../Icons/InventoryIcon";



const ItemSmallAdd = (props) => {

    let [item, setItem] = useState(props.itemData)
    let [caseQuantity, setCaseQuantity] = useState(0)
    

    useEffect(() => {
        
        item.cases.map(join => {

            if (join.id === props.caseId) {
                setCaseQuantity(join.Case_Item.quantity)
            }
        })
        
    }, [])

    let addToCase = () => {
        axios.put(`/api/items/addtocase/${props.itemData.id}`, {
            caseId: props.caseId
        }).then((response) => {
            setItem(response.data.item)
            setCaseQuantity(response.data.caseJoin.quantity)
        }).catch(function (error) {
            let message = error.response;
            if (message) {
                console.log(message)
            } else {
                console.log('Unknown Error')
            }
    
        });
    }

    let removeFromCase = () => {

        axios.put(`/api/items/removefromcase/${props.itemData.id}`, {
            caseId: props.caseId
        }).then((response) => {

            setItem(response.data.item)
            setCaseQuantity(response.data.caseJoin.quantity)
    
        }).catch(function (error) {
            let message = error.response;
            if (message) {
                console.log(message)
            } else {
                console.log('Unknown Error')
            }
    
        });
    }


    return <Fragment>
        { 
            item.quantity || caseQuantity ?
            <div className={`flex justify-between pb-2 mb-2 ${caseQuantity ? 'opacity-100' : 'opacity-50'}`}>
        
            <div className="flex">
                <div className="bg-gray-300 w-16 h-16 bg-cover rounded mr-2" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/uploaded_images/item_placeholder.jpg)`}}></div>

                <div className="flex flex-col">
                    <div><p className="text-sm font-bold">{props.itemData.name}</p></div>

                    <div className="flex items-center">
                            
                        <InventoryIcon size="sm"/>
                        {
                            item.quantity < 10 ?
                                <p className="text-xs text-red-500 font-bold">x{item.quantity}</p>
                            :
                                <p className="text-xs">x{item.quantity}</p>
                        }
                            
                        {
                            props.itemData.inCases > 0 ?
                                <div className="flex items-center">
                                    <CaseIcon size="sm"/>
                                    <p className="text-xs">x{props.itemData.inCases}</p>
                                </div>
                            :
                                null
                        }
                        
                    </div>

                    

                </div>
            </div>
            

            <div className="flex items-center">
                {
                    item.quantity ?
                    <a onClick={addToCase} className="btn btn-green" style={{padding: '.5rem 1rem'}}>+</a>
                    :
                    null

                }
                
                <input className="input" disabled style={{textAlign: 'center', width: '3ch', padding:'.5ch', margin: '0'}} value={caseQuantity} />
                {
                    caseQuantity ?
                    <a onClick={removeFromCase} className="btn btn-red" style={{padding: '.5rem 1rem'}}>-</a>
                    :
                    null
                }
                
            </div>

        </div>
            :
                null
        }
    </Fragment>
}

export default ItemSmallAdd;