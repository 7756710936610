import { Redirect } from "react-router-dom";

const ProtectedRoute = ({ getSessionUser, children }) => {
  const sessionUser = getSessionUser()
  if (!sessionUser) {
    return <Redirect to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;