import { Fragment } from "react"
import axios from "axios";
import { useHistory } from "react-router-dom";

import Input from "../components/Form/Input";

const Login = props => {

    const history = useHistory();

    let handleSubmit = (e) => {

        e.preventDefault();

        let username = document.querySelector('#username');
        let password = document.querySelector('#password');

        username.style.borderColor = null;
        password.style.borderColor = null;

        axios.post(props.signInUrl, {
            username: username.value.toLowerCase(),
            password: password.value.toLowerCase()
          }).then((response) => {

            const { accessToken } = response.data;
            localStorage.setItem('token', accessToken);
            localStorage.setItem('user', JSON.stringify(response.data));

            props.handleLogin(response)

            history.push('/home');

        }).catch(function (error) {

            let message = error.response.data.message;

            if (message === 'User Not found.') {
                username.style.borderColor = 'red';
            } else if (message === 'Invalid Password!') {
                password.style.borderColor = 'red';
            }

          });

    }

    return <Fragment>

        <section className="flex flex-col px-4 w-full md:max-w-3xl">
            <h1 className="text-3xl text-center mt-12 mb-24 capitalize">936b</h1>

            <form className="flex flex-col" onSubmit={handleSubmit}>
                <Input id="username" placeholder="ID" />
                <Input id="password" placeholder="Password" />

                <button className="btn btn-green" type="submit">Login</button>

            </form>

        </section>
        
    </Fragment>
}

export default Login
