import { Fragment } from 'react';


const LineItem = (props) => {

    return <Fragment>
            <div className='flex justify-between'>
                <div className="flex items-center capitalize">
                → {props.name} 
                </div>

                {
                    props.customCost ?
                    <div>${props.customCost} x{props.quantity}</div>
                        :
                    <div>${props.cost} x{props.quantity}</div>
                }
                
            </div>
            
            
    </Fragment>
}

export default LineItem;