import { Fragment, useState, useEffect } from "react"

import axios from "axios";
import { Link } from "react-router-dom";

import ShoppingIcon from "../components/Icons/ShoppingIcon";
import RunnerIcon from "../components/Icons/RunnerIcon";
import ClientIcon from "../components/Icons/ClientIcon";


const AddTransaction = (props) => {

    const [transactionData, setTransactionData] = useState({})
    const [transactionItems, setTransactionItems] = useState([])

    useEffect(() => {

        let transactionId = window.location.pathname.split("/").pop();

        axios.get(`${props.transactionURL}/${transactionId}`).then((response) => {
            setTransactionData(response.data);
            setTransactionItems(JSON.parse(response.data.items))
        });

    }, [])


    return <Fragment>

        <header className='flex flex-col w-full md:max-w-3xl justify-between w-full border-b-2 border-black' style={{minHeight: '150px'}}>

            <div className='flex flex-row items-center  p-4'>
                <div className="flex items-center">
                <span className="text-black mr-2"><ShoppingIcon size="sm" /> </span><h1 className="capitalize text-2xl text-black">Transaction #{transactionData.id}</h1> 
                </div>
                
            </div>

            <div className="flex ml-6 mb-8">
                <p>{new Date(transactionData.createdAt).toLocaleString()}</p>
            </div>

        </header>

        <section className="flex flex-col px-4 w-full md:max-w-3xl mb-20 pt-8">

        <p className="text-sm text-gray-600 capitalize">transaction note</p>
            <input className="input" disabled value={transactionData.note} />

            <div className="flex items-center mb-4">
                <div className="flex items-center mr-8"><span><ClientIcon size="sm" /></span>{transactionData.client ? transactionData.client.username : null}</div>
                <div className="flex items-center"><span><RunnerIcon size="sm" /></span>{transactionData.runner ? transactionData.runner.username : null}</div>
            </div>

            
            <div className="ml-3">
                {
                    transactionItems.map(item => {
                        return <div className='flex justify-between'>
                            <Link to={`/view-item/${item.id}`}className="flex items-center capitalize"> <p>→ {item.name}</p></Link>
                            <div className="flex items-center"><p className="mr-4">${item.customCost ? item.customCost : item.cost}</p> <p>x{item.quantity}</p></div>
                        </div>
                    })
                }
            </div>

            <div className="border-b mt-8 mb-8"></div>

            <div className="flex justify-end">
                        <p>Sub Total: ${transactionData.subTotal}</p>
                    </div>


            <div className="flex flex-col justify-end">
                        <div className="border-b w-[100px] mt-8 mb-8" style={{marginLeft: 'auto'}}></div>
                        <p className="font-bold" style={{marginLeft: 'auto'}}>Total: ${transactionData.totalCost}</p>
                    </div>

            
            
        </section>

    </Fragment>
}

export default AddTransaction;