import React from 'react';

import { Link } from 'react-router-dom';

import ClientIcon from '../Icons/ClientIcon';
import RewardIcon from '../Icons/RewardIcon';
import NoteIcon from '../Icons/NoteIcon';
import CalendarIcon from '../Icons/CalendarIcon';

const OrderCard = props => {

    const convertedDataTime = new Date(props.updatedAt).toLocaleString();

    return <div className="flex flex-col border-b-2 pb-8 mb-8">
        
      
        <p className="text-xs text-gray-500 ml-3">Added: {convertedDataTime}</p>
        <Link to={`/client/${props.client.id}`}>
            <div className='flex w-full'>

                <div className='flex items-center mr-4'>
                    <span><ClientIcon size='sm' /></span><p className="font-bold">{props.client.username}</p>
                </div>

                <div className='flex items-center'>
                    <span className="text-blue-500"><RewardIcon size='sm' /></span>
                    {
                        props.client.transactionsRemaining ?
                        <p className='text-xs text-blue-500'>{`${props.client.transactionsRemaining} Transactions Remain`}</p>
                        :
                        <p className='text-xs text-blue-500'>REWARD AVAILABLE</p>
                    }

                </div>

            </div>
        </Link>

            
        
        {
            props.note ?
                <div className='flex items-center ml-10'>
                    <span className="text-gray-500"><NoteIcon size='sm' /></span><p className="text-gray-500">{props.note}</p>
                </div>
            :
                null
        }
            

        {
                    props.scheduled ?
                        <div className='flex items-center ml-9'>
                            <span><CalendarIcon size='sm' /></span>
                            <span className='flex'>
                                <p>{new Date(props.scheduled).toLocaleString()}</p>
                            </span>
                        </div>
                    :
                    null
                }

        <Link className="btn btn-green mt-4" style={{width: 'fit-content'}}
            to={{
                pathname: "/make-transaction",
                state: {
                  clientData: props.client,
                  runnerData: props.runner,
                  orderId: props.id
                },
              }}
        >
            Checkout
        </Link>

    </div>
}

export default OrderCard;