import { Fragment, useState, useEffect } from "react"

import axios from "axios";
import { Link } from "react-router-dom";

import ShoppingIcon from '../components/Icons/ShoppingIcon';
import SectionHeader from '../components/Layout/SectionHeader';
import OrderCard from '../components/Orders/OrderCard';

const Orders = (props) => {

    const [orders, setOrders] = useState([]);
    const [scheduled, setScheduled] = useState([]);
    const [unassigned, setUnassigned] = useState([]);

    useEffect(() => {
        axios.get(props.ordersURL).then((response) => {
            let getScheduled = []
            let getAsap = []

            for (let order of response.data) {
                if (order.scheduled) {
                    getScheduled.push(order)
                } else {
                    getAsap.push(order)
                }
            }
            setOrders(getAsap)
            setScheduled(getScheduled)
        });

        axios.get(`${props.ordersURL}/unassigned`).then((response) => {
            setUnassigned(response.data)
        });
        
    }, [])


    return <Fragment>

        <header className='flex flex-row w-full md:max-w-3xl items-start' style={{backgroundColor: "var(--green)", minHeight: '150px'}}>
            <div className='flex flex-row items-center justify-between w-full p-4'>
                <div className="flex items-center">
                    <span className="text-white mr-2"><ShoppingIcon size="sm"/></span>
                    <h1 className="capitalize text-2xl text-white">orders</h1> 
                </div>

                <Link to="/add-order" className="btn btn-white text-white capitalize">Add</Link>

            </div>
        </header>

        <section className="flex flex-col px-4 w-full md:max-w-3xl">
            {
                unassigned.length ?
                    <Fragment>
                        <SectionHeader name='unassigned' />
                        {unassigned.map(order => {
                            return <OrderCard {...order}/>
                        })}
                    </Fragment>
                :
                    null
            }
            

            <SectionHeader name='current orders' />
            {orders.map(order => {
                    return <OrderCard {...order}/>
            })}
            
            <SectionHeader name='scheduled' />

            {scheduled.map(order => {
                return <OrderCard {...order}/>
            })}
        </section>

    </Fragment>
}

export default Orders;