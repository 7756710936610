import { Fragment } from 'react';
import classes from './Dropdown.module.css'

const Dropdown = props => {
    
    const data = props.data.map(
        user => <option className={`w-full uppercase`} value={user.id}>{user.username}</option>
    )

    return <Fragment>
        <p className="text-xs text-gray-500 mb-1 capitalize">{props.label}</p>
        <div className="flex">
            {
                props.icon ?
                props.icon
                :
                null
            }
            
            <select id={props.id} className="w-full uppercase">
                {
                    props.defaultOption ?
                        <option className="w-full uppercase" value={props.defaultOption[0]} selected>{props.defaultOption[1]}</option> 
                    :
                        <option className="w-full uppercase" value="0" selected>—</option> 
                }
                
                {data}
            </select>
        </div>
    </Fragment>
}

export default Dropdown;