import { Fragment, useState, useEffect } from "react"

import axios from "axios";
import { Link } from "react-router-dom";

import ShoppingIcon from '../components/Icons/ShoppingIcon';
import RunnerIcon from '../components/Icons/RunnerIcon';
import ClientIcon from '../components/Icons/ClientIcon';
import SectionHeader from "../components/Layout/SectionHeader";
import NoteIcon from "../components/Icons/NoteIcon";

const Transactions = (props) => {

    const [transactions, setTransactions] = useState([])

    useEffect(() => {

        axios.get(`${props.transactionURL}`).then((response) => {
            setTransactions(response.data);
        });

    }, [])


    return <Fragment>

        <header className='flex flex-col w-full h-full justify-between md:max-w-3xl items-start' style={{backgroundColor: "var(--green)", minHeight: '150px'}}>
            <div className='flex flex-row items-center justify-between w-full p-4'>
                <div className="flex items-center">
                    <span className="text-white mr-2"><ShoppingIcon size="sm"/></span>
                    <h1 className="capitalize text-2xl text-white">transactions</h1> 
                </div>

            </div>

            <div className="ml-7">
                <p className="mb-4 text-white uppercase">{props.sessionUser.username}</p>
            </div>
        </header>

        <section className="flex flex-col px-4 w-full md:max-w-3xl mb-20">

            <SectionHeader name="today"/>

      
                        {transactions.map(transaction => {
                            return <div key={transaction.id} className="border-b mb-4 pb-4">
                                <Link to={`view-transaction/${transaction.id}`} className="flex justify-between">
                                
                                    <div className="flex items-center font-bold"><span><ShoppingIcon size="sm" /></span>Transaction #{transaction.id}</div>
                                    <p className="font-bold">${transaction.totalCost}</p>
                                
                                </Link>
                                <div className="flex items-center ml-4 opacity-50">
                                    <div className="flex items-center mr-8"><span><ClientIcon size="sm" /></span>{transaction.client ? transaction.client.username : null}</div>
                                    <div className="flex items-center"><span><RunnerIcon size="sm" /></span>{transaction.runner ? transaction.runner.username : null}</div>
                                </div>
                                {transaction.note ? 
                                    <div className="flex items-center ml-4 opacity-50">
                                        <div className="flex items-center mr-8"><span><NoteIcon size="sm" /></span>{transaction.note}</div>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        })}
         

        </section>

        

    </Fragment>
}

export default Transactions;