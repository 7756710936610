import { Fragment } from "react"
import { useState, useEffect } from "react"

import { Link } from "react-router-dom"
import axios from "axios"

import SectionHeader from "../components/Layout/SectionHeader"
import ClientIcon from "../components/Icons/ClientIcon"
import RewardIcon from "../components/Icons/RewardIcon"
import NoteIcon from "../components/Icons/NoteIcon"
import ModIcon from "../components/Icons/ModIcon"
import RunnerIcon from "../components/Icons/RunnerIcon"


let getHighestRole = (roles) => {
    let result = false;
    roles.forEach(role => {
        if (role.name === 'admin') {
            result = role.name
        } else if (role.name === 'moderator') {
            result = role.name
        } else if (role.name === 'user') {
            result = role.name
        }
    })

    return result;
}

const People = (props) => {

    const [mods, setMods] = useState([]);
    const [runners, setRunners] = useState([]);
    const [clients, setClients] = useState([]);

    useEffect(() => {
        axios.get(props.usersURL).then((response) => {
            response.data.forEach(user => {
                let role = getHighestRole(user.roles)

                switch(role) {
                    case 'moderator':
                        setMods(oldArray => [...oldArray, user]);
                    break;
                    case 'user':
                        setRunners(oldArray => [...oldArray, user]);
                    break;
                    default:
                    // code block
                }

            })
        });

        axios.get(props.clientsURL).then((response) => {
            setClients(response.data);
          });

    }, []);

    const allClients = clients.map(
        user => <div className="flex flex-col mb-4">

            <Link to={`/client/${user.id}`} className="flex">
                
                <div className='flex items-center mr-4'>
                    <span><ClientIcon size='sm' /></span><p className="uppercase">{user.username}</p>
                </div>

                <div className="flex items-center text-blue-500">
                    
                    {user.hasOwnProperty('transactionsRemaining') ?
                    
                        user.transactionsRemaining ?
                        <Fragment><span><RewardIcon size='sm' /></span> <p className="text-xs">{`${user.transactionsRemaining} Transactions Remain`}</p></Fragment>
                        :
                        <Fragment><span><RewardIcon size='sm' /></span> <p className='font-bold text-xs'>REWARD AVAILABLE</p></Fragment>
                    
                    : <p className='text-red-500'>No Orders</p>}
                    

                </div>

            </Link>
            
            {
                user.note ?
                    <div className='flex items-center ml-10'>
                        <span className="text-gray-500"><NoteIcon size='sm' /></span><p className="text-gray-500">{user.note}</p>
                    </div>
                :
                null
            }

        </div>
    )

    const allMods = mods.map(
        user => <div key={`mod-${user.id}`} className='flex flex-col mb-4'>
            <Link to={`/moderator/${user.id}`}>
                <div className='flex items-center'>
                    <span><ModIcon size='sm' /></span><p className="uppercase">{user.username}</p>
                </div>
            </Link>

            {
                user.note ?
                    <div className='flex items-center ml-10'>
                        <span className="text-gray-500"><NoteIcon size='sm' /></span><p className="text-gray-500">{user.note}</p>
                    </div>
                :
                null
            }
            

        </div>

    )

    const allRunners = runners.map(
        user => <div className='flex flex-col mb-4'>
            <Link to={`/user/${user.id}`}>
                <div className='flex items-center'>
                    <span><RunnerIcon size='sm' /></span><p className="uppercase">{user.username}</p>
                </div>
            </Link>

            {
                user.note ?
                    <div className='flex items-center ml-10'>
                        <span className="text-gray-500"><NoteIcon size='sm' /></span><p className="text-gray-500">{user.note}</p>
                    </div>
                :
                null
            }
            

        </div>

    )

    return <Fragment>

        <header className='flex flex-col w-full h-full justify-between md:max-w-3xl items-start' style={{backgroundColor: "var(--green)", minHeight: '150px'}}>
            <div className='flex flex-row items-center justify-between w-full p-4'>
                <div className="flex items-center">
                    <span className="text-white mr-2"><ClientIcon size="sm" /></span>
                    <h1 className="capitalize text-2xl text-white">people</h1> 
                </div>

                <div>
                    <Link to="/add-person" className="btn btn-white">add</Link>
                </div>
            </div>
            <div className="ml-7">
                <p className="mb-4 text-white uppercase">{props.sessionUser.username}</p>
            </div>
        </header>

        <section className="flex flex-col px-4 w-full md:max-w-3xl mb-10">

            <SectionHeader name='moderators' />
            {allMods.length ? 
                allMods : 
                <div className='flex flex-col mb-4'>
                    <p className="text-red-500">No mods Found</p>
                </div>}

            <SectionHeader name='runners' />
            {allRunners.length ? 
                allRunners : 
                <div className='flex flex-col mb-4'>
                    <p className="text-red-500">No Runners Found</p>
                </div>}

            <SectionHeader name='clients' />

            {allClients.length ? 
                allClients : 
                <div className='flex flex-col mb-4'>
                    <p className="text-red-500">No Clients Found</p>
                </div>}

        </section>

    </Fragment>
}

export default People;