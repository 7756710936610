import { Fragment, useState, useEffect } from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";

import CaseIcon from "../Icons/CaseIcon";
import ModIcon from "../Icons/ModIcon";
import RunnerIcon from "../Icons/RunnerIcon";
import Dropdown from "../Form/Dropdown";

let hasRole = (roles, roleName) => {
    for (const role of roles) {
        if (role.name === roleName) {
            return true;
        }
    }
    return false;
}

let getUserByPk = (users, pk) => {
    for (const user of users) {
        if (user.id === pk) {
            return user;
        }
    }
    return false;
}

const GetCases = (props) => {
    const history = useHistory()

    const [moderators, setModerators] = useState([]);
    const [runners, setRunners] = useState([]);

    useEffect(() => {

        axios.get(`${props.usersURL}/moderators`).then((response) => {
            setModerators(response.data);
        });

        axios.get(`${props.usersURL}/users`).then((response) => {
            setRunners(response.data);
        });

    }, []);

    return <div className="flex flex-col border-b-2 pb-8 mb-8">
        
        <Fragment>
            <Link to={`/view-case/${props.case.id}`} className="flex">
            
                <div className='flex justify-between w-full'>

                    <div className='flex items-center mr-4'>
                        <span><CaseIcon size='sm' /></span><p className="font-bold uppercase">Case #{props.case.id}</p>
                    </div>

                    <div className='flex flex-col items-center'>
                        <div><p className="font-bold">Sold: ${props.case.soldAmount}</p></div>
                    </div>

                </div>

            </Link>


            <div className="flex flex-col ml-10">
                <div className='flex items-center mr-4'>
                    { props.case.moderatorId && props.case.moderatorId !== 1 ?

                        <Link to={`/moderator/${props.case.moderatorId}`} className="flex items-center">
                            <span><ModIcon size='sm' /></span><p className="uppercase">{getUserByPk(moderators, props.case.moderatorId).username}</p>
                        </Link>
                    :
                    <div className='flex items-center'>
                        <span><ModIcon size='sm' /></span><p className="uppercase text-red-600">No Mod Assigned</p>
                    </div>
                    
                    }
                    
                </div>

                <div className='flex items-center mr-4'>

                    { props.case.runnerId && props.case.runnerId !== 1 ?

                        <Link to={`/user/${props.case.runnerId}`} className="flex items-center">
                            <span><RunnerIcon size='sm' /></span><p className="uppercase">{getUserByPk(runners, props.case.runnerId).username}</p>
                        </Link>
                    :
                    <div className='flex items-center'>
                        <span><RunnerIcon size='sm' /></span><p className="uppercase text-red-600">No Runner Assigned</p>
                    </div>
                    }
                    
                </div>

                <div className="flex flex-col">
                    {
                        props.sessionUser  && !props.case.cashedOut && (props.sessionUser.roles[0] === 'moderator' || props.sessionUser.roles[0] === 'admin') ?
                        <Link to={`/start-close-out/${props.case.id}`} className="btn btn-green mt-4">
                            cash out
                            </Link>
                        :

                        null
                            
                            
                    }

                    {props.sessionUser && (props.sessionUser.roles[0] === 'moderator' || props.sessionUser.roles[0] === 'admin') && props.case.cashedOut && !props.case.cashedOutConfirmed
                        ?
                        <div className="flex justify-center w-full border-2 border-orange-300 rounded-md bg-orange-100 mb-8 p-4 mt-6">
                        <p className="text-orange-500 mb-1">Awaiting Admin sign off</p>
                    </div>
                        :  
                        null
                    }


                    {
                        props.sessionUser && props.sessionUser.roles[0] === 'admin' && props.case.cashedOut ?

                        <Fragment>
                        {/* <div className="btn btn-green" onClick={handleConfirmCashout}>
                            confirm cash out
                        </div> */}
                        <Link to={`/confirm-cash-out/${props.case.id}`} className="btn btn-green">
                            confirm cash out
                        </Link>
                        </Fragment>
                        :
                            null
                    }





                    
                    
                </div>

            </div>

        </Fragment>
        
    </div>
}

export default GetCases;