import { Fragment, useState, useEffect } from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";

import CaseIcon from "../Icons/CaseIcon";
import ModIcon from "../Icons/ModIcon";
import RunnerIcon from "../Icons/RunnerIcon";
import ShoppingIcon from "../Icons/ShoppingIcon";
import NoteIcon from "../Icons/NoteIcon";
import SectionHeader from "../Layout/SectionHeader";

// TODO: get case with mod and user, remove all GetUserByPkCose


const ConfirmCashOut = (props) => {
    const history = useHistory()
    const [caseData, setCaseData] = useState({})
    const [moderators, setModerators] = useState([]);
    const [runners, setRunners] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [errorMsg, setErrorMsg] = useState('')

    useEffect(() => {

        let caseId = window.location.pathname.split("/").pop();

        axios.get(`${props.casesURL}/${caseId}`).then((response) => {
            setCaseData(response.data)
        });

        axios.get(`${props.transactionURL}/case/${caseId}`).then((response) => {
            setTransactions(response.data);
        });

        axios.get(`${props.usersURL}/moderators`).then((response) => {
            setModerators(response.data);
        });

        axios.get(`${props.usersURL}/users`).then((response) => {
            setRunners(response.data);
        });

    }, []);

    let getUserByPk = (users, pk) => {
        for (const user of users) {
            if (user.id === pk) {
                return user;
            }
        }
        return false;
    }

    let handleConfirmCashout = (e) => {

        e.preventDefault();
    
        axios.post(`${props.casesURL}/confirm-cash-out/${caseData.id}`, {
        }).then((response) => {

            history.push(`/home`);
    
        }).catch(function (error) {
            let message = error.response.data.message;
    
            if (message) {
                console.log(message)
                // setErrorMsg(message)
            } else {
                // setErrorMsg('Unknown Error')
                console.log('Unknown Error')
            }
    
        });

    }



    return <Fragment>
     


        <header className='flex flex-col w-full h-full justify-between md:max-w-3xl items-start' style={{backgroundColor: "var(--green)", minHeight: '150px'}}>
            <div className='flex flex-row items-center justify-between w-full p-4'>
                <div className="flex items-center">
                <span className="text-white mr-2"><CaseIcon size="sm"/></span>
                    <h1 className="capitalize text-2xl text-white">Confirm Cash out</h1> 
                </div>

            </div>

            <div className="ml-7">
                <p className="mb-4 text-white uppercase">{props.sessionUser.username}</p>
            </div>

        </header>


        <section className="flex flex-col px-4 w-full md:max-w-3xl mb-20 mt-8">

        {
                errorMsg ?
                <div className="flex justify-center w-full border-2 border-red-300 rounded-md bg-red-100 mb-8 p-4">
                    <p className="text-red-500 mb-1">{errorMsg}</p>
                </div>
                :
                    null
            }

        <Link to={`/view-case/${caseData.id}`} className="flex">
            
                <div className='flex justify-between w-full'>

                    <div className='flex items-center mr-4'>
                        <span><CaseIcon size='sm' /></span><p className="font-bold uppercase">Case #{caseData.id}</p>
                    </div>

                    <div className='flex flex-col items-center'>
                    <p class="mt-4 ml-10">Collect: <span className="font-bold">${caseData.soldAmount}</span></p>
                    </div>

                </div>

            </Link>

                <div className='flex items-center ml-8'>

                <Link to={`/moderator/${caseData.moderatorId}`} className="flex items-center">
                        <span><ModIcon size='sm' /></span><p className="uppercase">{getUserByPk(moderators, caseData.moderatorId).username}</p>
                        </Link>

                        <Link to={`/user/${caseData.runnerId}`} className="flex items-center">
                            <span><RunnerIcon size='sm' /></span><p className="uppercase">{getUserByPk(runners, caseData.runnerId).username}</p>
                        </Link>
                    
                </div>
                <Link to={`/view-case/${caseData.id}`} className="font-bold mt-4 ml-10">
                    <p>View product to return</p>
                </Link>

                
                    <div onClick={handleConfirmCashout} className="btn btn-green mt-8">Finalize cash out</div>

                <div className="border-b mt-12"></div>


                {
                    transactions.length ?
                
                    <Fragment>
                <SectionHeader name='Case Transactions' />

                <div>
                {transactions.map(transaction => {
                            return <div className="border-b mb-4 pb-4">
                                <Link to={`/view-transaction/${transaction.id}`} className="flex items-center justify-between">
                                
                                    <div className="flex items-center font-bold"><span><ShoppingIcon size="sm" /></span>Transaction #{transaction.id}</div>
                                    <p className="font-bold">${transaction.totalCost}</p>
                                
                                </Link>
                                {transaction.note ? 
                                    <div className="flex items-center ml-4 opacity-50">
                                        <div className="flex items-center mr-8"><span><NoteIcon size="sm" /></span>{transaction.note}</div>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        })}
                </div>
                </Fragment>
                        :
                        null

                    }


        </section>



        </Fragment>
}

export default ConfirmCashOut;