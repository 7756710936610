import { Fragment, useEffect, useState } from "react"

import { Link, useHistory } from "react-router-dom"
import axios from "axios"

import SectionHeader from "../components/Layout/SectionHeader"
import ClientIcon from "../components/Icons/ClientIcon"
import RewardIcon from "../components/Icons/RewardIcon"
import ShoppingIcon from "../components/Icons/ShoppingIcon"
import NoteIcon from "../components/Icons/NoteIcon"

const Client = (props) => {

    const [userData, setUserData] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const history = useHistory()

    useEffect(() => {
        let userID = window.location.pathname.split("/").pop();
    
        axios.get(`${props.clientsURL}/${userID}`).then((response) => {
            setUserData(response.data)
            let clientNote = document.querySelector('#client-note')
            clientNote.value = response.data.note
        });

        axios.get(`${props.transactionURL}/client/${userID}`).then((response) => {
            setTransactions(response.data)
        });
    
    }, []);

    let handleSaveData = () => {
        let clientNote = document.querySelector('#client-note')
        let clientNoteValue = clientNote.value

        axios.put(`${props.clientsURL}/${userData.id}`, {
            note: clientNoteValue,
        }).then((response) => {
            history.push(`/client/${userData.id}`);
        })
    }

    return <Fragment>

     

        <header className='flex flex-col w-full h-full justify-between md:max-w-3xl items-start' style={{backgroundColor: "var(--green)", minHeight: '150px'}}>
            <div className='flex flex-row items-center justify-between w-full p-4'>
                <div className="flex items-center">
                <span className="text-white mr-2"><ClientIcon size="sm" /></span>
                    <h1 className="capitalize text-2xl text-white">client</h1>
                </div>

            </div>
            <div className="ml-7">
                <p className="mb-4 text-white uppercase">{userData.username}</p>
            </div>
        </header>

        <section className="flex flex-col px-4 w-full md:max-w-3xl mb-20">

            

            <p className="text-sm text-gray-600 mt-8">Note</p>
            <input id="client-note" className="input"/>

            <div onClick={handleSaveData} className="btn btn-green">Save Changes</div>

            <SectionHeader name='rewards' />

            {
                userData.transactionsRemaining ?
                    <div className="flex items-center text-blue-500"><span><RewardIcon size='sm' /></span> <p className="text-xs">{`${userData.transactionsRemaining} Transactions Remain`}</p></div>
                :
                    <div className="flex items-center text-blue-500"><span><RewardIcon size='sm' /></span> <p className='font-bold text-xs'>REWARD AVAILABLE</p></div>
            }



            <SectionHeader name={`transactions (${transactions.length})`} />

            {transactions.map(transaction => {
                            return <div key={transaction.id} className="border-b mb-4 pb-4">
                                <Link to={`/view-transaction/${transaction.id}`} className="flex justify-between">
                                
                                    <div className="flex items-center font-bold"><span><ShoppingIcon size="sm" /></span>Transaction #{transaction.id}</div>
                                    <p className="font-bold">${transaction.totalCost}</p>
                                
                                </Link>
                                {transaction.note ? 
                                    <div className="flex items-center ml-4 opacity-50">
                                        <div className="flex items-center mr-8"><span><NoteIcon size="sm" /></span>{transaction.note}</div>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        })}

        </section>

    </Fragment>
}

export default Client;