const InventoryIcon = props => {
    return (

        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        height="48" 
        width="48"
        fill={props.color ? props.color :  'currentColor'}
        viewBox={props.size ? "-12 -18 80 80" : "0 0 48 48"}
        >
<path d="M21.55 41.3H9q-1.2 0-2.1-.9-.9-.9-.9-2.1V9.2q0-1.2.775-2.1.775-.9 1.325-.9h10.1q.35-1.75 1.725-2.875T23.1 2.2q1.8 0 3.175 1.125Q27.65 4.45 28 6.2h10.1q1.2 0 2.1.9.9.9.9 2.1v10.15h-3V9.2h-5.3v6.5H14.3V9.2H9v29.1h12.55ZM31 40.05l-8-8 2.15-2.15L31 35.75 42.95 23.8l2.15 2.15ZM24 9q.85 0 1.425-.575Q26 7.85 26 7q0-.85-.575-1.425Q24.85 5 24 5q-.85 0-1.425.575Q22 6.15 22 7q0 .85.575 1.425Q23.15 9 24 9Z"/>        </svg>
    );
};

export default InventoryIcon;