import { Fragment, useState, useEffect } from "react"

import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";

import CaseIcon from "../components/Icons/CaseIcon";
import InventoryIcon from "../components/Icons/InventoryIcon";
import EditItemForm from "../components/Form/EditItemForm";





const AddItem = (props) => {
    const history = useHistory()

    const [itemData, setItemData] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [image, setImage] = useState(null);
    const [ressy, setRessy] = useState(null);


    useEffect(() => {

        if (!props.new) {
            let itemId = window.location.pathname.split("/").pop();
            axios.get(`${props.itemsURL}/${itemId}`).then((response) => {
                setItemData(response.data)
            });
        }
        
    }, []);

    function checkFileExtension(filename) {
        let extension = filename.split('.').pop();
    };

    const handleClick = () => {
        axios.post(props.uploadImageURL, image)
        .then((response) => {

            setRessy(response)

        })
      }

      const handleFileInput = (e) => {
        e.preventDefault()
        const formData = new FormData(); 
        formData.append('my-image-file', e.target.files[0], e.target.files[0].name);
        setImage(formData);

      }

    return <Fragment>

        <header className={`flex flex-row w-full md:max-w-3xl border-b-2 items-start mb-4`}>
            <div className='flex flex-row items-center justify-between w-full p-4'>
                <div className="flex flex-col justify-between w-full">
                    {
                        props.edit === false && props.new === false ? 
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <span className="text-black mr-2"><InventoryIcon size="sm" /> </span><h1 className="capitalize text-2xl text-black">{itemData.name}</h1>
                                </div>
                                {
                                    props.sessionUser && (props.sessionUser.roles[0] === 'admin' || props.sessionUser.roles[0] === 'moderator')  ?
                                    <Link to={`/edit-item/${itemData.id}`} className="btn btn-green">Edit</Link>
                                    :
                                    null
                                }
                                
                            </div>
                        : 
                            null
                    }

                    { 
                        props.edit === true && props.new === false ?
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <span className="text-black mr-2"><InventoryIcon size="sm" /> </span><h1 className="capitalize text-2xl text-black">{itemData.name}</h1>
                                </div>
                            </div>
                    :
                        null
                    }

                    {
                        props.new === true ? 
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <span className="text-black mr-2"><InventoryIcon size="sm" /> </span><h1 className="capitalize text-2xl text-black">Add New Item</h1>
                                </div>
                            </div>
                        : 
                            null
                    }

                </div>
            </div>
        </header>

        <section className="flex flex-col px-4 w-full md:max-w-3xl  mb-20">

            <div className="flex items-center justify-center h-20 w-full bg-gray-300 min-h-[300px] bg-cover bg-center rounded mb-6" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/uploaded_images/item_placeholder.jpg)`}}>
                { props.edit || props.new ? 
                <Fragment>


                    {/* <div className="flex flex-col">
                        <input type="file" onChange={handleFileInput}></input>
                        <a className="btn btn-green" onClick={handleClick}>Upload!</a> 
                    </div> */}
                    
                    
                    </Fragment>
                : 
                    null
                }
            </div>

            {
                props.edit === false && props.new === false ? 
                    <Fragment>
                        <p className="text-xs text-gray-500 mt-2">Name</p>
                        <p className="ml-2 mb-4 capitalize">{itemData.name}</p>
                        
                        <p className="text-xs text-gray-500 mt-2">Note</p>
                        <p className="ml-2 mb-4 capitalize">{itemData.note}</p>
                        
                        <p className="text-xs text-gray-500 mt-2">Storage Quantity</p>
                        {
                            itemData.quantity < 10 ?
                                <p className="ml-2 mb-4 text-red-500 font-bold capitalize">{itemData.quantity}</p>
                            :
                                <p className="ml-2 mb-4 capitalize">{itemData.quantity}</p>
                        }
                        
                        <p className="text-xs text-gray-500 mt-2">Category</p>
                        <p className="ml-2 mb-4 capitalize">{itemData.category}</p>
                        
                        {
                            itemData.customCost ?
                             <Fragment>
                                <p className="text-xs text-gray-500 mt-2">Custom Cost</p>
                                <p className="ml-2 mb-4 capitalize">{itemData.customCost}</p>
                             </Fragment>
                             :
                             <Fragment>
                                <p className="text-xs text-gray-500 mt-2">Sub Category</p>
                                <p className="ml-2 mb-4 capitalize">{itemData.subItemCategory ? itemData.subItemCategory.name : null} - ${itemData.subItemCategory ? itemData.subItemCategory.cost : null}</p>

                             </Fragment>

                        }
                        
                        
                        
                        <p className="text-xs text-gray-500 mt-2">Type</p>
                        <p className="ml-2 mb-4 capitalize">{itemData.itemType}</p>
                        
                        <p className="text-xs text-gray-500 mt-2">Description</p>
                        <p className="ml-2 mb-4 capitalize">{itemData.description}</p>
                        
                    </Fragment>
                : 
                    null
            }

            { 
                props.edit === true && props.new === false ?
                    <EditItemForm 
                        new={props.new} 
                        itemData={itemData} 
                        itemsURL={props.itemsURL} 
                        itemCategoriesURL={props.itemCategoriesURL} 
                        subItemCategoriesURL={props.subItemCategoriesURL}
                        itemTypesURL={props.itemTypesURL}
                    />
                :
                    null
            }

            {
                props.new === true ? 
                    <EditItemForm 
                        new={props.new} 
                        itemsURL={props.itemsURL} 
                        itemCategoriesURL={props.itemCategoriesURL} 
                        subItemCategoriesURL={props.subItemCategoriesURL}
                        itemTypesURL={props.itemTypesURL}
                    />
                : 
                    null
            }
            
        </section>

    </Fragment>
}

export default AddItem;