import { Fragment, useState, useEffect } from "react"
import { Link } from "react-router-dom";

import axios from "axios";

import InventoryIcon from '../components/Icons/InventoryIcon';
import ItemSmall from "../components/Item/ItemSmall";
import SectionHeader from "../components/Layout/SectionHeader";

const Inventory = (props) => {

    const [items, setItems] = useState([]);
    const [itemCategories, setItemCategories] = useState([]);
    const [sessionUser, setSessionUser] = useState(props.sessionUser);

    useEffect(() => {
        axios.get(`${props.itemsURL}`).then((response) => {
            setItems(response.data);
        });

        axios.get(`${props.itemCategoriesURL}`).then((response) => {
            setItemCategories(response.data);
        });

    }, []);

    return <Fragment>

        <header className='flex flex-col w-full h-full justify-between md:max-w-3xl items-start' style={{backgroundColor: "var(--green)", minHeight: '150px'}}>
            <div className='flex flex-row items-center justify-between w-full p-4'>
                <div className="flex items-center">
                    <span className="text-white mr-2"><InventoryIcon size="sm" /></span>
                    <h1 className="capitalize text-2xl text-white">storage</h1> 
                </div>

                <div>
                    <Link to="/new-item" className="btn btn-white">add</Link>
                </div>
            </div>

            <div className="ml-7">
                <p className="mb-4 text-white uppercase">{sessionUser.username}</p>
            </div>

        </header>

        <section className="flex flex-col px-4 w-full md:max-w-3xl mb-20">

            {
                itemCategories.map((category) => 
                    <Fragment>
                        <SectionHeader name={category.name} />

                        <Fragment>
                            {items.map((item) =>
                                { return item.category === category.name ?
                                    <ItemSmall key={item.id} itemData={{...item}}/>
                                :
                                null
                                }
                            )}
                        </Fragment>
                        
                    </Fragment>
                )
            }

        </section>

    </Fragment>
}

export default Inventory