import { Fragment, useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"

import axios from "axios"

import SectionHeader from "../components/Layout/SectionHeader"
import RunnerIcon from "../components/Icons/RunnerIcon"
import MakeCase from "../components/Cases/MakeCase"

const User = (props) => {

    const history = useHistory()

    const [userData, setUserData] = useState([]);
    const [userCase, setUserCase] = useState([]);

    useEffect(() => {
        let userID = window.location.pathname.split("/").pop();
    
        axios.get(`${props.usersURL}/${userID}`).then((response) => {
            setUserData(response.data.user)
            setUserCase(response.data.user.case)

            let clientNote = document.querySelector('#user-note')
            clientNote.value = response.data.user.note
            
        });
    
    }, []);

    let handleSaveData = () => {
        let clientNote = document.querySelector('#user-note')
        let clientNoteValue = clientNote.value

        axios.put(`${props.usersURL}/${userData.id}`, {
            note: clientNoteValue,
        }).then((response) => {
            history.push(`/user/${userData.id}`);
        })
    }

    let handleSavePassword = () => {
        let userPassword = document.querySelector('#user-password')
        let userPasswordValue = userPassword.value

        axios.put(`${props.usersURL}/password/${userData.id}`, {
            password: userPasswordValue,
        }).then((response) => {
            userPassword.value = ''
            history.push(`/user/${userData.id}`);
        })
    }

    return <Fragment>



        <header className='flex flex-col w-full h-full justify-between md:max-w-3xl items-start' style={{backgroundColor: "var(--green)", minHeight: '150px'}}>
            <div className='flex flex-row items-center justify-between w-full p-4'>
                <div className="flex items-center">
                <span className="text-white mr-2"><RunnerIcon size="sm" /></span>
                    <h1 className="capitalize text-2xl text-white">runner</h1>
                </div>

            </div>
            <div className="ml-7">
                <p className="mb-4 text-white uppercase">{userData.username}</p>
            </div>
        </header>



        <section className="flex flex-col px-4 w-full md:max-w-3xl mb-20">

        <p className="text-sm text-gray-600 mt-8">Note</p>
            <input id="user-note" className="input"/>

            <div onClick={handleSaveData} className="btn btn-green">Save Changes</div>


            { 
            
                props.sessionUser && (props.sessionUser.roles[0] === 'admin' || props.sessionUser.roles[0] === 'moderator')  ?

                    <Fragment>
                        <SectionHeader name='settings' />

                        <p className="text-sm text-gray-600 capitalize">new password</p>
                        <input id="user-password" className="input"/>

                        <a onClick={handleSavePassword} className="btn btn-red mb-4">save new password</a>

                    </Fragment>

                :

                    null

            }

            

        </section>

    </Fragment>
}

export default User;